////////////////////////////////////////////////////////////////////////////////
// *
// * Field: files
// *
////////////////////////////////////////////////////////////////////////////////


// -- Uploaded files

.field-name-field-files {
  margin-top: rem($base-vertical-space);

  .field-item {
    padding-bottom: rem($base-vertical-space / 2);
    margin-bottom: rem($base-vertical-space / 2);
    border-bottom: 1px solid $color-border;
  }
}
