////////////////////////////////////////////////////////////////////////////////
// *
// * Config: Variables: Typography
// *
////////////////////////////////////////////////////////////////////////////////


// * Base
// =============================================================================
// -- Set a base font-size (dont use px!) & font-family

$base-font-size: 14;
$base-line-height: 19;
$base-font-family: 'Open Sans', 'Arial', sans-serif;

// Sets global base em size for the px-to-em and px-to-rem function.
// This should be the same size as your html font-size. Setting default is 16px.
$em-base: 16;

// in case of a webfont with multiple weights such as 'light' and 'semi-bold'
// you'll need numbers instead of 'normal' and 'bold'
$font-weight-normal: 400;
$font-weight-bold: 700;

// -- basis for our vertical rhythm: use for margins top & bottom (usually same as line-height)
$base-vertical-space: $base-line-height;

// * Headings
// =============================================================================
// -- Set your h1, h2, ... size (don't use px!) and color

$font-size-h1: 20;
$line-height-h1:(20 * 1.2);
$color-h1: $color-heading;

$font-size-h1-page: 20;
$line-height-h1-page:(20 * 1.2);
$color-h1-page: $white;

$font-size-h2: 19;
$line-height-h2:(19 * 1.2);
$color-h2: $color-heading;

$font-size-h3: 18;
$line-height-h3:(22);
$color-h3: $color-base;

$font-size-h4: 14;
$line-height-h4:(14 * 1.2);
$color-h4: $color-heading;

// * General
// =============================================================================

$font-size-intro: 18;
$line-height-intro: 22;

// * Icons
// =============================================================================
// -- Declare font-awesome icons and use them in the font-awesome-mixin

// ** Social
$icon-facebook: '\f09a';
$icon-twitter: '\f099';
$icon-linkedin: '\f0e1';
$icon-instagram: '\f16d';
$icon-googleplus: '\f0d5';
$icon-pinterest: '\f0d2';
$icon-email: '\f0e0';
$icon-email-o: '\f003';

// ** Users
$icon-login: '\f090';
$icon-logout: '\f08b';
$icon-user: '\f2be';
$icon-register: '\f2bd';

// ** Navigation
$icon-hamburger: '\f0c9';

// ** Custom
$icon-angle-right: '\f105';
$icon-angle-left: '\f104';
$icon-angle-double-right: '\f101';
$icon-angle-double-left: '\f100';
$icon-check: '\f00c';
$icon-phone: '\f095';
$icon-mobile: '\f10b';
$icon-location: '\f041';
$icon-calendar: '\f073';
$icon-caret-down: '\f0d7';
$icon-caret-up: '\f0d8';
$icon-caret-right: '\f0da';
$icon-caret-left: '\f0d9';
$icon-chevron-down: '\f078';
$icon-chevron-up: '\f077';
$icon-chevron-right: '\f054';
$icon-chevron-left: '\f053';
$icon-search: '\f002';
$icon-times: '\f00d';
$icon-cog: '\f013';
$icon-play-circle: '\f144';
$sort: '\f0dd';
$icon-comments: '\f086';
$icon-remove: '\f00d';
$icon-delete: '\f1f8';
$icon-edit: '\f040';
$icon-reply: '\f112';
$icon-document: '\f15b';
$icon-calc: '\f1ec';
$icon-briefcase: '\f0b1';
$icon-quote-left: '\f10d';
$icon-quote-right: '\f10e';
$icon-cart: '\f07a';
$icon-trash: '\f014';
$icon-lock: '\f023';
$icon-unlock: '\f13e';
$icon-check-circle: '\f058';
