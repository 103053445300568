/**
 * View news carousel
 */
.news-carousel {

  .gallery__section {
    background-color: transparent;
    padding: 0 10px;
  }

  .gallery__row {
    margin-left: rem(-40);
    transition: left .5s $easing;
  }

  .node {
    margin-left: rem(40);
    float: left;
  }

  .gallery__controls {
    @include clearfix;
    position: absolute;
    top: rem(-50);
    right: 0;
    a {
      opacity: 1;

      &:after {
        @include font-size(34);
      }
    }

    .gallery__prev {
      left: rem(-100);
    }

    .gallery__next {
      right: 0;
    }
  }

  /**
   * Breakpoints
   */
  @include bp-xs-wide {
    .node {
      margin-left: rem(40);
      float: left;
    }
  }
  @include bp-sm {
    .gallery__section {
      padding: 0 44px;
    }
    .node {
      margin-left: rem(40);
      float: left;
    }
  }
  @include bp-md {
    .gallery__section {
      padding-left: 0;
    }
  }
}
