////////////////////////////////////////////////////////////////////////////////
// *
// * Tables
// *
////////////////////////////////////////////////////////////////////////////////


// * CSS from Mothership
// =============================================================================

.table-order-asc,
.table-order-desc {
  @include square(rem(16));
  float: right;
}

.table-order-asc {
  background: transparent url('/misc/arrow-asc.png') no-repeat center top;
}

.table-order-desc {
  background: transparent url('/misc/arrow-desc.png') no-repeat center top;
}

// * Tables: general styling
// =============================================================================

table {
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  text-align: left;
  border: 1px solid $color-border;
  border-collapse: collapse;
  border-spacing: 0;

  // striped table (when class is aded by user, eg in CKE)
  &.is-striped tbody {
    tr:nth-child(odd) {
      background: $color-border;

      td {
        border-left: 1px solid $white;
        border-right: 1px solid $white;

        &:first-child {
          border-left: 1px solid $color-border;
        }
        &:last-child {
          border-right: 1px solid $color-border;
        }
      }
    }
  }

  tr {
    // by default: styling for headings in columns
    th {
      padding: rem(10);
      font-weight: bold;
      text-align: left;

      // headings in a column
      color: $white;
      background: lighten($color-spot-1, 10);
      border: 1px solid $color-spot-1;
      border-left: 1px solid $white;
      border-right: 1px solid $white;

      &:first-child {
        border-left: 1px solid $color-spot-1;
      }
      &:last-child {
        border-right: 1px solid $color-spot-1;
      }
    }

    td {
      padding: rem(10);
      border-bottom: 1px solid $color-border;
      border-left: 1px solid $color-border;
      border-right: 1px solid $color-border;
    }
  }

  // styling headings in rows
  thead,
  tfoot {

    tr {
      th {
        background: $color-spot-1;
        border-left: 1px solid $white;
        border-right: 1px solid $white;

        &:first-child {
          border-left: 1px solid $color-spot-1;
        }
        &:last-child {
          border-right: 1px solid $color-spot-1;
        }
      }
    }
  }

  tbody {
    tr {

      td {
        padding: rem(10);
      }

      // striped rows (generated by Drupal)
      &.odd {
        background: $color-border;

        td {
          border-left: 1px solid $white;
          border-right: 1px solid $white;

          &:first-child {
            border-left: 1px solid $color-border;
          }
          &:last-child {
            border-right: 1px solid $color-border;
          }
        }
      }
    }
  }
}

// ** responsive tables (in content)
// -- option to reformat or to use a scrollbar: 'reformatted' or 'scroll'
.table-responsive {
  &.has-scroll {
    @include table-responsive('scroll');
  }
  &.is-reformatted {
    @include table-responsive('reformatted');
  }
}
