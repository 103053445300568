////////////////////////////////////////////////////////////////////////////////
// *
// * Page: error 404, 403, …
// *
// *
////////////////////////////////////////////////////////////////////////////////


// * General error page
// =============================================================================

.page__error {

  // don't need the secondary header on error pages

  header.is-secondary {
    @include hide;
  }

  .row--main__content {
    position: relative;
    margin: 0 auto;
    //max-width: (100% / 12 * 8);
    transition: margin $duration $easing;

    // ** circle

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      margin-bottom: rem(18);
      width: rem(166);
      height: rem(166);
      border-radius: 50%;
      background: $color-spot-2;
    }

    // ** icon

    &:after {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 0;
      margin-left: rem(-83);
      padding-top: rem(64);
      width: rem(166);
      height: rem(166);
      color: $white;
    }

    // ** text

    text-align: center;
    color: $color-spot-1;
    @include font-size(18, 25);

    h1,
    h2 {
      @extend %h1;
      margin-bottom: rem(28);
      color: $color-spot-1;
    }

    p,
    ul,
    ol {
      margin-bottom: rem(25);
    }

    @include bp-sm {

      margin-top: 40px;

      &:before {
        margin-bottom: rem(38);
      }
    }

    @include bp-lg {

      //margin-top: 170px;
      //margin-bottom: 170px;

      &:before {
        margin-bottom: rem(58);
      }

    }
  }
}


// * 404
// =============================================================================

.page__error__404 {

  .row--main__content {

    // ** icon

    &:after {
      content: '404';
      @extend %h1;
    }
  }
}


// * 403
// =============================================================================

.page__error__403 {

  .row--main__content {

    // ** icon

    &:after {
      content: '403';
      @extend %h1;
    }
  }

  #user-login-form {
    margin-bottom: rem(20);
  }
}
