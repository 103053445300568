////////////////////////////////////////////////////////////////////////////////
// *
// * Article (News)
// *
////////////////////////////////////////////////////////////////////////////////


.node-article {
  // -- make a group to wrap date, author, tags and sharing

  .group-meta {
    @include clearfix;

    // author, date, …
    [class*='field-name-'] {
      margin-bottom: 0;

      @include bp-sm {
        display: inline-block;
      }
    }
  }

  //** Teaser (in main content for example)
  // =============================================================================

  &.view-mode-teaser,
  &.view-mode-article_teaser {
    h2,
    h3 {
      @extend %h2;

      a {
        @include links($color-h4, darken($color-link, 10%));
      }
    }

    // -- make a group to wrap date and author

    .group-meta {

      margin-bottom: rem($base-vertical-space);

      @include bp-sm {
        // author/date
        [class*='field-name-'] {
          float: left;
        }

        // tags
        .field-name-field-article-tags {
          margin-left: rem(15);
          float: right;
          text-align: right;
        }
      }
    }
  }

  //** Teaser (in main content for example)
  // =============================================================================
  &.view-mode-brand_carousel_item {
    .field-type-image {
      margin-bottom: rem(45);
      img {
        margin: 0 auto;
        border-radius: 100%;
      }
    }

    h3 {
      @extend %h2;
      margin-bottom: rem(22);

      a {
        color: $color-spot-1;
        &:hover {
          color: darken($color-spot-1, 10%);
        }
      }
    }
  }

  //** Related (eg. under detail post)
  // =============================================================================

  &.view-mode-article_related,
  &.view-mode-related {
    .group-meta {
      margin-bottom: rem($base-vertical-space);
    }
  }

  //** Simple teaser (eg. latest articles in sidebar)
  // =============================================================================

  &.view-mode-teaser_simple,
  &.view-mode-article_teaser_simple {

    margin-bottom: rem($base-vertical-space);
    padding-top: rem($base-vertical-space);
    border-top: 1px solid $color-border;

    .field-name-submitted-by,
    .field-name-post-date,
    .field-name-author {
      margin-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }

  //** Full
  // =============================================================================

  &.view-mode-article_full,
  &.view-mode-full {

    h1 {
      margin-bottom: rem($base-vertical-space * 1.5);
    }

    // -- make a group to wrap date and author

    .group-meta {
      padding: rem($base-vertical-space/2) rem($base-vertical-space);
      background: $color-offwhite;

      .sharethis-buttons {
        margin-top: rem(10);
      }

      @include bp-sm {
        // tags
        .field-name-field-article-tags {
          margin-left: rem(30);
        }

        // share buttons
        .sharethis-buttons {
          float: right;
          margin: 0 0 0 rem(10);
        }
      }

    }

  }

}
