////////////////////////////////////////////////////////////////////////////////
// *
// * Image CTA Bean
// *
////////////////////////////////////////////////////////////////////////////////

// Use this file to style the image cta bean.
// Set the default settings below. Don't guess, check the design for these settings!
// Some parameters always need a value - these parameters are assigned an asterisk.
//
// If there are instances of image cta beans that differ from the default styling, create a new parameters
// variable and pass this along in the mixin. See the sample code at the bottom.
//
// $color: (*) the color of the text and icon.
// $font-size: (*) the font-size of the text. NO PX!
// $line-height: the line-height of the text. NO PX!
// $text-align: (*) set this to either left, right or center to align the text - default is left.
// $imgfloat: set this to either left or right, to float the image - default is none. NOTE: don't use this with view-mode-image_bottom!
// Only to be used with view-mode-image_top!

// * Parameters
// =============================================================================

// ** DEFAULT PARAMETERS

$default: (
  color: $color-grey-light,
  color-title: $color-h4,
  font-size: $base-font-size,
  line-height: $base-line-height,
  text-align: left,
  imgfloat: none,
);

// ** CUSTOM PARAMETERS

// $var-name: (
//   color: ...,
// )


// * Examples
// =============================================================================

// !! EXAMPLE CODE !!
// $floated: (
//   color: #A5A5A5,
//   font-size: 13,
//   line-height: 18,
//   align: left,
//   imgfloat: right,
// );
//
// .bean-image-cta-bean {
//   .someclass & {
//     &.view-mode-image_top {
//       @include image-cta-bean($floated);
//     }
//   }
// }
// !! EXAMPLE CODE !!


// * Bean
// =============================================================================

.bean-image-cta-bean {

  @include image-cta-bean($default);

  .field-name-field-link-cta-bean {
    a {
      @include button-secondary;
    }
  }

  // &.view-mode-image_top {
  //   @include image-cta-bean($default);
  // }

  // &.view-mode-image_bottom {
  //   @include image-cta-bean($default);
  // }
}
