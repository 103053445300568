////////////////////////////////////////////////////////////////////////////////
// *
// * Main structure
// *
// * Info & examples:
// * http://neat.bourbon.io/
// * http://neat.bourbon.io/examples/
// *
////////////////////////////////////////////////////////////////////////////////


// * Page containers
// =============================================================================

// this wrapper is only used for the fixed footer
// but also has a use to prevent vertical scrollbar
.page-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
}

.container,
.row--full .container--conditional, // used in CTA beans filling a row
%container {
  //@include outer-container; // use this if no max-width & clearfix mixins used
  @include clearfix;
  @include max-width; // use if you want to limit the max-width
  padding-left: rem($padding-container);
  padding-right: rem($padding-container);
  width: 100%;
}

// -- container resets

.container {
  .container--conditional, // reset if already has a container as a ancestor
  .row--full & { // reset because this div shouldn't have one
    padding: 0;
    margin: 0;
    margin: 0;
    max-width: none;
  }
}


// * Rows
// =============================================================================
// -- Used with Panels and Display Suite templates


// ** Rows with columns
// -- These will contain an .container wrapper and 1 or several columns
// -- More on columns in the 'Grids' section belog

.row--cols {
  // usually nothing special to declare
}

// ** Rows without columns
// -- The whole row is filled with the block, bean, view, whatever is inside

.row--full {
  // nothing in here, class can serve as a hook
  // For elements that need to fill the row
  // Usually a CTA, CTA background image or Google map will go in here
}


// * Main content
// =============================================================================

// ** spacing around the main content
// -- if panels are used: the class is added in panel templates eg. coolflex_detail
// -- on other kinds of pages, the wrapper is added in the .tpl eg. page--search.tpl.php


// ** Default row
// TODO: turn into a mixin of some kind???

.row {

  .container {
    padding-top: rem(45);
    padding-bottom: rem(20); // + padding of children == 45

    // bottom margin on the children
    // means less padding on the bottom of the row container
    .node,
    .entity-bean,
    form {
      margin-bottom: rem(24); // + 20 from container == 45
    }

    .bean-title-bean {
      margin-bottom: 0; // margin comes from heading inside it
    }

    .row--main__aside .node {
      margin-bottom: rem($base-vertical-space);
    }
  }

  @include bp-sm {
    .container {
      padding-top: rem(70);
      padding-bottom: rem(50); // + padding of children == 70
    }
  }
}

// ** For main content row
// -- eg. on contact page or event detail

.row--main {
  .container {
    padding-top: rem(30);

    @include bp-sm {
      padding-top: rem(75); //try rem(30) if it's too much
    }
  }
}
