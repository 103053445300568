////////////////////////////////////////////////////////////////////////////////
// *
// * User
// *
////////////////////////////////////////////////////////////////////////////////

// * Login form
// =============================================================================

.block-user-login {
  margin-top: rem(20);

  h2 {
    @include hide;
  }
}

#user-login,
#user-pass,
#user-login-form {
  position: relative;
  float: none;
  background-color: darken($color-offwhite, 1%);
  max-width: rem(350);
  padding: rem(34) rem(20) rem(20) rem(20);
  margin: 0 auto;
  // text-align: center;

  @include font-awesome($icon-lock, $color-base, 21px, ':after', middle, 0);

  &:before,
  &:after {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    content: '';
    width: rem(48);
    height: rem(48);
    top: rem(-22);
    background-color: $white;
    border: 2px solid $color-border;
    border-radius: 50%;
  }

  &:after {
    top: rem(-8);
  }

  // the input+label wrappers
  > div {
    margin: 0 0 rem(15) 0;
  }

  input[type='submit'],
  button[type='submit'] {
    width: 100%;
  }

  ul {
    position: absolute;
    bottom: rem(5);
    right: rem(20);
    @include list-reset;
    @include font-size($base-font-size);
    text-align: right;

    li {
      margin-bottom: rem(5);
      @include font-size(12);
      &:before {
        display: none;
      }
    }

    @include bp-sm {

      bottom: rem(10);
      right: rem(20);

      li {
        display: inline;

        a {
          display: inline-block;
          margin-left: rem(10);
        }
      }
    }
  }
}

.page-user-password {
  .sub-header {
    display: none;
  }
}

#user-login {
  small {
    display: none;
  }
}

.confirm-parent {
  .password-confirm {
    .ok {
      color: $color-status;
    }
    .error {
      color: $color-error;
    }
  }
}


// * User Register & Edit profile
// =============================================================================

#user-profile-form,
.user-info-from-cookie {

  // no decent input wrappers, so need to add margin to labels instead

  .fieldset-description,
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    margin-bottom: rem(10);
  }

  small {
    margin-top: rem(-5);
    margin-bottom: rem(10);
  }
}

// * User Login during checkout
// =============================================================================

// ** reset the fieldsets

#commerce-checkout-form-login {
  //
}

.login_register_anon_pane {

  @include grid-row;

  .commerce-extra-login-page-description {
    width: 100%;
    margin: 0 rem($gutter / 2) rem($base-vertical-space) rem($gutter / 2);
    @include font-size($font-size-intro, $line-height-intro);
    // text-transform: uppercase;
  }

  & > .messages {
    width: 100%;
    margin: 0 rem($gutter / 2) rem($base-vertical-space) rem($gutter / 2);
  }

  fieldset {
    border: 0 none;
    margin-bottom: rem($base-vertical-space);
    @include grid-columns-responsive(12, 12, 6, 6);

    // block titles
    legend {
      @extend %h3;
      padding: 0;
      margin-bottom: 0;
    }

    // make visual blocks
    .fieldset-wrapper {
      border: 1px solid $color-border;
      padding: rem($gutter / 2);
    }

    // no decent input wrappers, so need to add margins here instead
    .fieldset-description,
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      margin-bottom: rem(10);
    }

    small {
      margin-top: rem(-5);
      margin-bottom: rem(10);
    }

    input[type='checkbox'] {
      margin-bottom: rem(10);
    }

    // links under the fields
    ul:last-child {
      @include list-reset;
      @include font-size($base-font-size);
      margin-top: rem(10);

      li {
        margin-bottom: rem(5);
        @include font-size(12);
      }

      @include bp-sm {

        bottom: rem(-24);

        li {
          display: inline;

          a {
            display: inline-block;
            margin-right: rem(10);
          }
        }
      }
    }

    // reset other lists
    .description {
      ul {
        @include list-styling;
        overflow: hidden; // fix overlap bullets with floating elements
        padding-left: 0;

        li {
          @include font-size(10); // same size as small text
        }
      }
    }
  }
}


