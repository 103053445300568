////////////////////////////////////////////////////////////////////////////////
// *
// * Bean: Contact Bean
// *
////////////////////////////////////////////////////////////////////////////////

// Use this file to style the contact bean.
// Set the default settings below. Don't guess, check the design for these settings!
// Some parameters always need a value - these parameters are assigned an asterisk.
//
// If there are instances of contact beans that differ from the default styling, create a new parameters
// variable and pass this along in the mixin. See the sample code at the bottom.
//
// $color: (*) the color of the text.
// $hovercolor: (*) the hover color of the text.
// $font-size: (*) the font-size of the text. NO PX!
// $line-height: the line-height of the text. NO PX!
// $showicon: set this to false if the icons need to be hidden - default is true.
// $icon-color: the color of the icon.
// $icon-size: the font-size of the icon. NO PX!
// $icon-background: the background for the icon, usually a plain color
// $border-width: if the background needs a border, set width
// $border-style: if the background needs a border, set style
// $border-color: if the background needs a border, set color
// $border-radius: if the background needs to be rounded
// $inline: if it needs to be as next to eachother or as a list

// * Parameters
// =============================================================================

// ** DEFAULT PARAMETERS
$dark: (
  color: $color-grey-light,
  hovercolor: $color-grey,
  font-size: $base-font-size,
  line-height: $base-line-height,
  showicon: true,
  icon-color: $color-grey-light,
  icon-size: 14,
  // icon-background: black,
  // border-width: 1px,
  // border-style: solid,
  // border-color: $white,
  // border-radius: 50%,
  inline: false
);

$light: (
  color: $color-grey-light,
  hovercolor: $white,
  font-size: $base-font-size,
  line-height: $base-line-height,
  showicon: true,
  icon-color: $color-grey-light,
  icon-size: 14,
  inline: false
);

$header: (
  color: $white,
  hovercolor: $color-heading,
  font-size: 12,
  line-height: 18,
  showicon: true,
  icon-color: $white,
  icon-size: 12,
  inline: true
);

// ** CUSTOM PARAMETERS
// $var-name: (
//   color: ...,
// )


// * Example
// =============================================================================

// !! EXAMPLE CODE !!
// $header: (
//   color: #B5B5B5,
//   hovercolor: #000000,
//   font-size: 14,
// );
//
// .bean-contact-bean {
//   header & {
//     &.view-mode-contact_teaser {
//       @include contact-bean($header);
//     }
//   }
// }
// !! EXAMPLE CODE !!


// * Contact Bean
// =============================================================================

.bean-contact {

  // ** Normal bean

  .main &.view-mode-contact_full {
    @include contact-bean($dark);
    margin-bottom: rem($base-vertical-space);
  }

  .doormat &.view-mode-contact_full {
    @include contact-bean($light);
  }

  // ** bean in header

  .header-top &,
  &.view-mode-contact_teaser {
    @include contact-bean($header);
  }

  // -- In header
  &.view-mode-header {
    display: none;
    a {
      &:hover {
        color: lighten($color-spot-1, 20%);
      }
    }
    // -- Fields
    .field-type-link-field {
      @include font-awesome($icon-phone, $white, rem(12));
      &:before {
        margin-right: rem(6);
      }
    }

    .field-type-email {
      a {
        text-decoration: underline;
      }

      @include font-awesome($icon-email-o, $white, rem(12));
      &:before {
        margin-right: rem(6);
      }
    }

    .field-type-text {
      padding-left: 0;
    }

    /**
     * Breakpoints
     */
    @include bp-sm {
      display: block;
      float: left;

      > div {
        float: left;
        margin-right: rem(15);
      }
    }
  }
}

// -- contact in row from Parallax
.row--parallax .bean-contact.view-mode-default {
  .field-name-field-contact-telephone {
    margin-bottom: rem(30);
    @include font-size(18);

    a {
      &:hover {
        color: $color-spot-1;
      }
    }
  }
}

