/**
 * Text bean
 */

.row--contact {
  .bean-text {
    @include font-size(18);
    margin-bottom: rem(40);

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
