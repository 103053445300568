/**
 * Contact form block
 */

.entitytype-contact_us-form {
  .form-wrapper {
    ::-webkit-input-placeholder {
      color:transparent;
    }

    :-moz-placeholder { /* Firefox 18- */
      color:transparent;
    }

    ::-moz-placeholder {  /* Firefox 19+ */
      color:transparent;
    }

    :-ms-input-placeholder {
      color:transparent;
    }
  }

  label {
    color: $white;
    &.required {
      &:after {
        content: '';
      }
    }
  }
  .form-actions {
    margin: 0;

    button {
      background-color: $white;
      color: $color-btn-secondary;
      padding-left: rem(45);
      padding-right: rem(45);
      &:hover {
        background-color: darken($white, 7%);
      }
    }
  }
}

.row .container .entitytype-contact_us-form {
  margin-bottom: 0;
}
