////////////////////////////////////////////////////////////////////////////////
// *
// * Mixins: Typography
// *
////////////////////////////////////////////////////////////////////////////////


// * Handy Bourbon mixins
// =============================================================================
//
// * hide-text; to hide text
// * word-wrap; break long url's
// * ellipsis(50%); with an optional percentage, Truncate text with '…' at the end
//
// -- For more Bourbon mixins see: http://bourbon.io/docs/


// * Overview
// =============================================================================

// ** font-smoothing
// ** font-size
// ** font-awesome
// ** hide-icontext
// ** text-nobreak
// ** m-p-reset
// ** list-reset
// ** list-styling
// ** links
// ** button
// ** button-size


// * Mixins
// =============================================================================

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// ** Font-size mixin: easily set font-size and line-height of an element
// -- NOTE: default font-size is 13, default line-height is 18
// -- USAGE: @include font-size(15, 18) (don't use px!) -> sets font-size to 15 and line-height to a ratio of 18/15 = 1.1385

@mixin font-size($size:13, $line: $size * (18/13) ) {
  font-size: rem($size);
  line-height: rem($line);
}

// ** Font-awesome mixin: add a font-awesome icon before or after an element
// -- NOTE: before is default, margin is default 0.
// -- USAGE: @include font-awesome($icon-facebook, $red, 14px, before, middle, 0 5px 0 0);

@mixin font-awesome($content, $color, $size, $position:before, $align: middle, $margin...) {
  &:#{$position} {
    content: $content;
    font-family: 'FontAwesome';
    font-size: $size;
    display: inline-block;
    color: $color;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    $margin: if(length($margin) == 0, 0px, $margin);
    margin: $margin;
    vertical-align: $align;
  }
}

// ** hide text of an element with a font-awesome icon without hiding icon itself

@mixin hide-icontext() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  &:before {
    text-indent: -100%; // compensate for text-indent
  }
}

// ** Don't break up text

@mixin text-nobreak {
  white-space: nowrap;
  -ms-word-break: keep-all;
  word-break: keep-all;
}

// ** Break long strings (eg. emailaddresses)

@mixin word-break($hyphen:false) {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  @if ($hyphen) {
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

// ** remove all padding and margin on element

@mixin m-p-reset() {
  margin: 0;
  padding: 0;
}

// ** reset list style

@mixin list-reset() {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    display: block;
  }
}

// ** Styling for lists in text
// -- http://www.456bereastreet.com/archive/201105/styling_ordered_list_numbers/
// -- USAGE: @include list-styling without parameter, defaults to unsorted style
// --        @include list-styling('sorted') gives you a sorted lists style

@mixin list-styling($list:null) {

  @if $list == 'sorted' {
    display: block;
    counter-reset:li; /* Initiate a counter */
    margin-left:0; /* Remove the default left margin */
    padding-left: 0; /* Remove the default left padding */

    & > li {
      list-style:none; /* Disable the normal item numbering */
      position:relative; /* Create a positioning context */
      display: block;
      margin-left: rem(30); /* Give each list item a left margin to make room for the numbers */
      padding-left: rem(5); /* Add some spacing around the content */

      &:before {
        content:counter(li) '.'; /* Use the counter as content */
        counter-increment:li; /* Increment the counter by 1 */
        /* Position and style the number */
        position:absolute;
        top:0;
        left: rem(-30); // same as li margin left to balance out the position
        width: rem(24); // space enough to render multiple digits
        text-align: right;
        color: $color-spot-1;
      }
    }

  } @else {
    display: block;
    padding-left: 0;
    margin-left: 0;

    // first level: disc bullet

    & > li {
      list-style: none;
      position: relative;
      display: block;
      padding-left: rem(15);

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: rem(2);
        top: rem(7);
        width: rem(4);
        height: rem(4);
        background: $color-spot-1;
        border-radius: 50%;
      }

      ul {
        padding-left: rem(20);
      }

      // second level: circle bullet

      li {
        &:before {
          width: rem(5);
          height: rem(5);
          background: transparent;
          border: 1px solid $color-spot-1;
        }

        // third level: square filled bullet

        li:before {
          width: rem(4);
          height: rem(4);
          background: $color-spot-1;
          border: 0 none;
          border-radius: 0;
        }
      }
    }
  }
}

// ** Links mixin: use this to style the links

@mixin links($link, $hover, $visited: $link, $active: $hover) {
  &,
  &:visited {
    color: $visited;
  }
  &:hover {
    color: $hover;
  }
  &:active,
  &:focus {
    color: $active;
    &:hover {
      color: darken($active, 10%);
    }
  }
}

// ** Button mixins: use this for buttons used on the site
// -- OPTIONS:
//    - background: the background-color you want (will darken on hover), default from config = spot color
//    - color: the text color you want (no change on hover by default), default from config = white
//    - border: a border around your button, will darken on hover
//    - size: default is false (normal size), it references another mixin to set more sizes (@mixin button-size)
// -- NOTE: remove what doesn't apply to the buttons used on the site or edit to your needs

@mixin button-size($size) {
  @if ($size == 'sm') {
    padding: rem(5) rem(10) rem(3) rem(10);
    min-height: 24px;
    @include font-size(12);
  }
}

@mixin button($background:$color-btn-primary-bg, $color:$color-btn-primary, $border:false, $size:'md') {
  cursor: pointer;
  display: inline-block;
  padding: rem(7) rem(84);
  min-height: 36px;
  @include font-size(13);
  font-weight: 400;
  text-decoration: none;
  border-radius: rem(20);
  transition: background $duration $easing;

  @if ($background) {
    @if ($background == 'transparent') {
      background: transparent;
    } @else {
      background: $background;
    }
  } @else {
    background: transparent;
  }

  @if ($border) {
    border: 1px solid $border;
  } @else {
    border: 0 none;
  }


  // load other sizes (if any)
  @include button-size($size);

  &,
  &:visited {
    color: $color;
  }

  &:hover,
  &:active {
    @if ($background) {
      // if transparent (or no) background,
      // see if there's a border and use that color for hover effect background
      // it's nicer than just changing text color
      @if ($background == 'transparent') {
        color: $white;
        @if ($border) {
          background: darken($border, 10%);
        } @else {
          background: transparent;
        }
      } @else {
        color: $color;
        background: darken($background, 10%);
      }
    } @else {
      color: $white;
      @if ($border) {
        background: darken($border, 10%);
      } @else {
        background: transparent;
      }
    }
    outline: none;
    @if ($border) {
      border: 1px solid darken($border, 10%);
    }
  }

  &:focus,
  &:focus:hover {
    outline: 0;
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    pointer-events: none; // no support in IE10
    cursor: default;
    color: desaturate(lighten($color, 20%), 80%);
    @if ($background) {
      @if ($background == 'transparent') {
        background: transparent;
      } @else {
        background: desaturate(rgba($background, .5), 80%);
      }
    } @else {
      background: transparent;
    }
    @if ($border) {
      border: 1px solid desaturate(rgba($border, .5), 80%);
    } @else {
      border: 0 none;
    }
  }
}

@mixin button-primary($size:'md') {
  @include button($color-btn-primary-bg, $color-btn-primary, false, $size);
}

@mixin button-secondary($size:'md') {
  @include button($color-btn-secondary-bg, $color-btn-secondary, $color-btn-secondary, $size);
}

@mixin button-tertiary($size:'md') {
  @include button(darken($color-btn-primary-bg, 15%), $white, $color-btn-primary-bg, $size);
}
