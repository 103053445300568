////////////////////////////////////////////////////////////////////////////////
// *
// * Headers
// *
// * Styling for the header and positioning for the children
// *
////////////////////////////////////////////////////////////////////////////////

// * Header top
// =============================================================================

$height-header-top: 42;

.header-top {
  position: relative;
  min-height: rem($height-header-top);
  background: $color-header-top-bg;
  font-size: rem(12);
  color: $white;

  .container {
    position: relative;
    padding-top: rem(11);
    padding-bottom: rem(11);
  }

  a {
    color: $white;
    &:hover {
      color: lighten($color-spot-1, 20%);
    }
  }
}


// Language
.header-top__locale,
.header__locale {
  position: relative;
  z-index: 1; // has to be higher than contactinfo in case of overlap
  float: right;
  padding: 0 0 0 rem(10); // space for search next to it
  margin: 0 rem(-5) rem(-6) rem(15); // compensate for padding from social links
}

// in case of dropdown
// .lang_dropdown_form {
//   float: right;
//   margin-left: rem(24);
//   padding-left: rem(14);
//   border-left: 1px solid $color-border;
// }


// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * Breakpoint: tablet & wider
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

@include bp-sm {
  // Language selection
  // if logged in, move the wrappers for contextual links
  .header-top__locale,
  .header__locale
  .lang_dropdown_form {
    float: right;
    padding-right: 0;
  }

  // contactinfo
  // if logged in, move the wrappers for contextual links
  [class*='header-top__bean--contact'] {
    float: left;
  }
}
@include bp-md {
  .header-top {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// * Header primary
// =============================================================================

// ** Settings for logo & navigation position in config/variables/_layout.scss

header.is-primary {
  position: relative;
  background: $white;
  @include clearfix;

  // ** wrapper for the mobile navigation + searchblock

  .wrapper-navigation {
    clear: both;
  }

  // ** trigger for mobile menu

  .wrapper-navigation .nav-title {
    @if ($hamburger-align != 'full') {
      display: block;
      position: absolute;
      top: rem(21);
    }

    @if ($hamburger-align == 'left') {
      left: rem(10);
    }
    @if ($hamburger-align == 'right') {
      right: rem(10);
    }

    @if ($hamburger-align == 'full') {
      //
    }
  }
}

// ** Logo block

.siteinfo {
  padding-top: rem(16); // space to top of header
  padding-bottom: rem(16); // space to the bottom of header

  // when hamburger menu is to the left, logo needs to float right
  @if ($hamburger-align == 'left') {
    float: right;
    // or else the logo needs to stay on the left
  } @else {
    float: left;
  }

  .logo {
    display: block;
    // compensate for bits of the logo overlapping with the spacing of the header
    //margin-left: rem(-14);
    width: rem(180);

    img {
      width: 100%;
      height: auto;
      transition: all $duration $easing;
    }
  }

  // Hide sitename/slogan but keep visible to screenreaders
  .site-name,
  .site-slogan {
    @include hide;
  }
}

// ** Main navigation

.nav-section-primary {
  clear: both;
}

// ** secondary nav

.nav-section-secondary {
  display: none;
}

// ** Header CTA

[class*='header__bean--cta'],
[class*='nav-panel__bean--cta'],
[class*='nav-primary__bean--cta'],
[class*='nav-secondary__bean--cta'] {
  margin: 0 rem(-$padding-container);
}


// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * Breakpoint: tablet & wider
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

@include bp-sm {

  header.is-primary {
    @include clearfix;

    .container {
      position: relative;
    }

    // ** Mobile menu trigger
    // don't need this on bigger screens
    .wrapper-navigation .nav-title {
      @include hide;
    }

    // ** navigation wrapper
    // reset the wrapper, won't need it for bigger screens
    .wrapper-navigation {
      clear: none;
    }
  }

  // ** Site logo

  .siteinfo {
    padding-top: rem(20); // space to top of header
    margin-left: rem(-14);

    .logo {
      width: rem(211);
    }

    @if ($logo-position == 'left') {
      float: left;
      padding-bottom: rem(20); // space to bottom of header

    } @else {
      float: none;
      padding-bottom: 0;
      //margin-bottom: rem(-20); // use this to compensate for top margin of main nav in case it goes below the logo

      .logo {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  // ** Header CTA

  [class*='header__bean--cta'],
  [class*='nav-panel__bean--cta'],
  [class*='nav-primary__bean--cta'],
  [class*='nav-secondary__bean--cta'] {

    &,
    & .bean-cta-bean
    & .bean-cta-bean .field-name-field-link-cta-bean-bean {
      float: left;
    }
  }

  // ** Main navigation
  // -- Settings for navigation position in config/variables/_layout.scss

  .nav-section-primary {

    @if ($nav-main-type == 'float') {

      // add some space for CTA button or shopping cart
      margin: rem(30) (100% / 12 * 2) rem(20) 0;
      float: right;
      clear: none;

      & > ul {
        margin: 0 rem(-10);
      }

    } @else {

      margin: rem(20) 0 0 0;
      float: none;
      clear: both;
    }
  }

  // ** Secondary nav

  .nav-section-secondary {
    display: none;
  }
}


// * Secondary header
// =============================================================================

// ** contains title, breadcrumb, …

header.is-secondary {
  padding-top: rem(12);
  padding-bottom: rem(5);
  text-align: left;
  color: $color-page-header;
  background: $color-page-header-bg;
  transition: padding $duration $easing;

  h1 {
    @include font-size($font-size-h1-page, $line-height-h1-page);
    color: $color-page-header;
  }

  @include bp-sm {
    padding-top: rem(20);
    padding-bottom: rem(15);
  }

  @include bp-md {
    padding-top: rem(34);
    padding-bottom: rem(33);
  }

  @if ($page-header-type == 'image') {
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;

    @include bp-sm {
      min-height: rem(200);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include bp-md {
      min-height: rem(335);
    }
  }
}


// * Sticky header
// =============================================================================

// only on bp-sm
// works with the JS function: fixedTop()

.sticky-spacer {
  display: none;
}

@include bp-sm {
  .sticky-spacer {
    display: block;
    height: 0;
  }

  .sticky-top {
    position: relative;
    top: 0;
    // transition: none;

    &.is-fixed {
      position: fixed;
      width: 100%;

      left: 0;
      z-index: 900;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .12);
    }
  }

  // the elements which will need a transition effect to make compact
  .header__block,
  .nav-panel__block,
  .nav-section-primary,
  .nav-section-secondary,
  .siteinfo,
  .siteinfo .logo {
    transition: left $duration $easing, top $duration $easing, right $duration $easing, bottom $duration $easing, width $duration $easing, margin $duration $easing, padding $duration $easing, width $duration $easing, max-width $duration $easing;
  }

  // make more compact when scrolling
  .js-scrolling {

    // change header padding

    .nav-section-primary,
    .header__menu--main-menu {

      @if ($nav-main-type == 'float') {
        margin-top: rem(10);
        margin-bottom: rem(10);
      } @else {
        margin-top: rem(20);
        margin-bottom: 0;
      }
    }

    .nav-section-secondary,
    .header__menu--corporate-menu {
      top: rem(10);
    }

    // change logo scaling + alignment to keep it to the left
    .siteinfo {
      padding-top: rem(10);
      padding-bottom: rem(10);

      .logo {
        width: rem(135);

        @if ($logo-position == 'left') {
          margin-left: rem(-9);
        }
      }
    }
  }

  // -- hide top nav again when scrolling down

  .js-scrolling-down {
    .sticky-top {
      &.is-fixed {
        //top: rem(-$height-header-top);
      }
    }
  }

  // -- show top nav again when scrolling up

  .js-scrolling-up {
    .sticky-top {
      &.is-fixed {
        top: rem(0);
      }
    }
  }

  // -- add easing when we're further down to page, to prevent jump
  /* stylelint-disable */
  .js-scrolling {
    .sticky-top {
      &.is-fixed.not-top {
        transition: top .35s ease;
      }
    }
  }
  /* stylelint-enable */

  // -- compensate for height of admin-menu

  .admin-menu .sticky-top.is-fixed {
    top: rem(0);
  }

  .js-scrolling-up.admin-menu .sticky-top.is-fixed {
    top: rem($height-header-top);
  }
}
