////////////////////////////////////////////////////////////////////////////////
// *
// * Page: Form thank you
// *
// *
////////////////////////////////////////////////////////////////////////////////


// * General
// =============================================================================

// replace by the body class name you need or make custom page tpl to add this one
.path-content-thanks {

  header.is-secondary {
    @include hide;
  }

  .col--content--full {
    margin: 0 auto;
  }

  .row--main__content {
    position: relative;
    margin: 0 auto;
    //max-width: (100% / 12 * 8);
    transition: margin $duration $easing;

    // ** circle

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      margin-bottom: rem(18);
      width: rem(166);
      height: rem(166);
      border-radius: 50%;
      background: $color-spot-2;
    }

    // ** icon

    @include font-awesome($icon-check, $white, 40px, ':after');

    &:after {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 0;
      margin-left: rem(-83);
      padding-top: rem(72);
      width: rem(166);
      height: rem(166);
    }

    // ** text

    text-align: center;
    color: $color-spot-1;
    @include font-size(18, 25);

    h1,
    h2 {
      @extend %h1;
      margin-bottom: rem(28);
      color: $color-spot-1;
    }

    p,
    ul,
    ol {
      margin-bottom: rem(35);
    }

    @include bp-sm {

      margin-top: 40px;

      &:before {
        margin-bottom: rem(38);
      }
    }

    @include bp-lg {

      // margin-top: 170px;
      // margin-bottom: 170px;

      &:before {
        margin-bottom: rem(58);
      }

    }
  }
}
