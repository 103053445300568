////////////////////////////////////////////////////////////////////////////////
// *
// * Mixins: Various
// *
////////////////////////////////////////////////////////////////////////////////


// * Handy Bourbon mixins
// =============================================================================
//
// ** clearfix; to add a clearfix
// ** hide-text; to hide text
// ** word-wrap; break long url's
// ** ellipsis(50%); with an optional percentage, Truncate text with '…' at the end
// ** transition(none !important); // kill transitions
// -- For more Bourbon mixins see: http://bourbon.io/docs/


// * Overview
// =============================================================================

// ** square
// ** block-fill
// ** full-bg
// ** table-responsive
// ** img-responsive
// ** fluid-ratio
// ** hide
// ** img-overlay
// ** stretch-row-teasers-content()
// ** align-teasers-buttons
// ** owlcarousel-flexbox


// * Mixins
// =============================================================================

// ** Square mixin

@mixin square($dimensions) {
  width: $dimensions;
  height: $dimensions;
}

// ** fill the wrapper

@mixin block-fill() {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

// ** Adds fullwidth background using given color.

@mixin full-bg ($color, $position: before) {
  position: relative;
  z-index: 5;
  &:#{$position} {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin-left: calc((-100vw + 100%) / 2);
    height: 100%;
    width: 100vw;
    background-color: $color;
    z-index: -1;
  }
}

// ** Responsive tables
// -- option to reformat or to use a scrollbar: 'reformatted' or 'scroll'

@mixin table-responsive($type) {

  @include bp-xs-only {

    // -- https://css-tricks.com/responsive-data-tables/
    @if ($type == 'reformatted') {

      /* Force table to not be like tables anymore */
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      thead {
        background: transparent;
        border: 0 none;
        tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
      }

      tr {
        border: 0 none; // we'll set borders in the td's

        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid $color-border;
          position: relative;
          padding-left: 50%;

          &:before {
            // include the row title
            content: attr(data-title)': ';
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 10px;
            left: 10px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            font-weight: bold;
          }
        }

        &.odd {
          td {
            border-bottom: 1px solid $white;
          }
        }
      }

      // no need for the extra padding and ":" when no th's
      table.no-th {
        tr td {
          width: auto;
          position: static;
          &:before {
            display: none;
          }
        }
      }

      // -- The bootstrap way: with horizontal scrollbar with long cells
    } @else {

      width: 100%;
      min-height: .01%;
      margin-bottom: rem($base-vertical-space * 2);
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      // border: 1px solid $color-border;

      table {
        table-layout: auto;
        border: 0 none;
        margin-bottom: 0;
      }
    }
  }
}

// ** Image extendable: set image width to 100% and height to auto
// -- USAGE: @include img-responsive()      without param, uses width
// -- USAGE: @include img-responsive(true)  set param to true to use max-width instead

@mixin img-responsive($max: false) {
  @if ($max == false) {
    width: 100%;
  } @else {
    max-width: 100%;
  }
  height: auto;
}

// ** keep an element in certain ratio
// -- USAGE: set this on the wrapper of an image in order to keep the img
//           centered and in a certain ratio
// -- OPTIONS:
//    - ratio to use (eg 3/4)
//    - placeholder: set a background-image url in case of empty image, set to false if not used
//    - element: the field outputting the image, need this to position everything

@mixin fluid-ratio($ratio: '3/4', $element:'.field-type-image, .field-type-video-embed-field') {
  position: relative;
  // hide image overflowing
  overflow: hidden;
  // keep the wrapper in a certain ratio using padding % & resetting heights
  padding-top: ($ratio * 100%);
  height: 0;
  min-height: 0;

  // stretch element to fill the space (eg. iframe or some kind of wrapper)
  #{$element} {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  // if our element is an image, or contains an image
  // center the img
  #{$element} img,
  img#{$element} {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }
}

// ** Remove an element completely from view

@mixin hide {
  position: absolute;
  display: block;
  padding: 0 !important;
  left: -9999px !important;
  height: 0px !important;
  line-height: 0px !important;
  background: none !important;
}

// ** overlay on an image

@mixin img-overlay($element:after, $opacity-start:0, $opacity-end:$overlay-opacity, $color:$overlay-color) {
  display: block;
  position: relative;
  transition: all $duration $easing;

  &:#{$element} {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color, $opacity-start);
    z-index: 2; // over the image
    transition: all $duration $easing;
  }

  @if ($overlay-scale != null and $overlay-scale != false) {
    transform: scale(1);
  }

  img {
    position: relative;
    z-index: 0; // under overlay, but over the placeholder
    transition: all $duration $easing;
  }

  &:hover {

    @if ($overlay-scale != null and $overlay-scale != false) {
      transform: scale(1.01);
    }

    &:after {
      background: rgba($color, $opacity-end);
    }
  }
}

// ** align buttons in a row of blocks at the bottom

// -- in use with ds templates 'teaser with …' in rows of columns
//    eg. in panels/_panels-front.scss and entities/_view-modes-teasers.scss
//    if you're using image at the top or bottom

// -- This mixin stretches the blocks, in columns, in a row
//    eg. in panels/_panels-front.scss, for usp blocks on frontpage
@mixin stretch-row-teasers-content() {

  @include bp-sm {

    .col {
      display: flex;
      flex-wrap: wrap;
    }

    .pane-block {
      display: flex;
      width: 100%; // IE10 fix: otherwise content too wide (full page width)
    }

    .container--conditional {
      display: flex;
      flex-direction: column;
      width: 100%; // IE10 fix: otherwise content too wide (full page width)
    }
  }
}

// -- This mixin aligns the read-more button in a block
//    eg. in panels/_panels-front.scss
//    eg. in entities/_view-modes-teasers.scss
@mixin align-teasers-buttons($button, $col: null, $align-horizontal:null) {

  @include bp-sm {

    // stretch the column the button is in
    @if ($col != null) {
      #{$col} {
        display: flex;
      }

      // stretch all the wrappers around the button
    } @else {
      .entity-bean {
        display: flex;
        width: 100%; // IE10 fix: otherwise content too wide (full page width)
      }

      .ds-image {
        display: flex;
        flex-shrink: 1;
      }

      .field-type-image {
        width: 100%; // IE11 fix: otherwise content too wide (full page width)
      }
    }

    .ds-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%; // IE11 fix: otherwise content too wide (full page width)
    }

    // position the button wrapper to the bottom
    #{$button} {
      display: flex;
      flex-grow: 1;
      align-items: flex-end; // align content to the bottom

      @if ($align-horizontal == 'right') {
        text-align: right;
        justify-content: flex-end; // align button link to the right
      }
    }
  }
}


@mixin owlcarousel-flexbox() {

  // -- override the inline block style to force equal height on the slides
  .owl-wrapper {
    display: flex !important;
    flex-wrap: nowrap;
  }

  .owl-item {
    overflow: hidden; // prevent slide images from showing when scaling the width

    .cols {
      width: calc(100% + #{rem($gutter)});
    }
  }

  .owl-item,
  .owl-item > [class*='item-'] {
    display: flex;
    width: 100%; //IE fix (some of it will be overridden by JS of owlcarousel but that's okay)
  }

  .node-slider {
    display: flex;
    width: 100%; // IE fix
    align-items: center;
    min-height: rem(286); // match the min-height for the slider & text
    transition: min-height $duration $easing;
  }

  .ds-content {
    flex: 1;
    position: relative;
    z-index: 1; // higher than image for overlay
    padding-top: rem(50);
    padding-bottom: rem(50);
  }

  .ds-visual {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 0;
      // scale in proportion
      height: 100%; // match the slide height
      width: auto; // leave it to stretch (images will most likely be in landscape format)
      min-height: rem(286); // match the min-height for the slider & text
      max-width: rem(2000); // something big enough to make sure the height that follows will be big enough
      transition: min-height $duration $easing;
    }
  }
}


// * Custom mixins
// =============================================================================
// -- Add your own custom mixins
// If you think you have a mixin that we would all benefit from,
// share it as an issue labeled 'request' on Gitlab so we can include it
