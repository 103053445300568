////////////////////////////////////////////////////////////////////////////////
// *
// * Admin
// *
// *
////////////////////////////////////////////////////////////////////////////////

// * Messages
// =============================================================================

// **  Do this not to annoy back-end devs ;)

body.admin-menu .drupal-messages {
  position: relative;
  z-index: 9; // over the reset of the header
  div.messages {
    margin-bottom: 0;
  }
}

body.has-tabs {
  .drupal-messages {
    top: 42px; // height tabs;
  }

  .page-wrapper > .kint {
    position: relative;
    top: 42px; // height tabs;
  }
}

@include bp-md {

  // -- When logged in, tabs need to be fixed too

  .has-tabs {
    &.admin-menu .sticky-top {
      margin-top: 42px; // height of tabs
    }

    &.admin-menu nav.tabs {
      position: fixed;
      top: 28px; // height of admin toolbar
      left: 0;
      min-height: 42px;
      z-index: 998; // has to go over fixed header but under admin bar
    }
  }
}

// ** Main settings

div.messages {
  position: relative;
  clear: both;
  margin-top: 0;
  padding: rem(15) rem(30) rem(15) rem(60); /* LTR */
  background-size: rem(30) rem(30);
  background-position: rem(10) rem(10); /* LTR */
  background-repeat: no-repeat;
  opacity: 1;
  transform: translate(0, 0);
  //transition: transform 1s $easing, -moz-transform 1s linear;
  transition: all .5s $easing;

  .close {
    position: absolute;
    right: rem(5);
    top: rem(15);
    display: block;
    color: $white;
    outline: none;
    border: none !important; // override any inline styles or admin css
    @include square(rem(40));
    @include hide-text;

    &:before {
      content: '+';
      text-indent: 0;
      top: 0;
      left: 0;
      position: absolute;
      transition: opacity $duration $easing;
      transform: rotate(45deg);
      font-size: rem(50);
      @include square(rem(40));
    }
    &:hover:before {
      opacity: 0.7;
    }

    &:after {
      display: none;
    }
  }

  ul {
    margin: 0 0 0 1em; /* LTR */
    padding: 0;
    li {
      list-style-image: none;
    }
  }

  // Animating the closing of message
  // slide to the left

  &.closing {
    opacity: 0;
    transform: translate(-100%, 0);
    //transition: transform .5s $easing, -moz-transform .5s linear;
    transition: all .5s $easing;

    @include bp-md {
      transform: translate(0, -100%);
    }
  }

  &.closed {
    display: none;
  }
}

// ** Types of messages (status)

div.status,
.ok,
div.warning,
.warning,
div.error,
.error {
  color: $white;
  border-radius: rem(3);
}

div.status,
.ok {
  a {
    color: $white;
  }
}

div.status {
  background-image: url('../images/ok.png');
  background-position: 1% 48%;
  border: 0;

  a {
    position: relative;
    display: inline-block;
    color: $white;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      height: 1px;
      width: 100%;
      background: $white;
      opacity: 1;
      transition: all $duration $easing;
    }

    &:hover,
    &:active {
      color: $white;

      &:after {
        opacity: 1;
        width: 0;
        left: 50%;
      }
    }
  }
}

div.status,
table tr.ok {
  background-color: $color-status;
  border: 1px solid $color-status-dark;
}

div.warning {
  background-image: url('../images/warning.png');
  background-position: 1% 48%;
  border: 0;

  a {
    position: relative;
    display: inline-block;
    color: $white;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      height: 1px;
      width: 100%;
      background: $white;
      opacity: 1;
      transition: all $duration $easing;
    }

    &:hover,
    &:active {
      color: $white;

      &:after {
        opacity: 1;
        width: 0;
        left: 50%;
      }
    }
  }

}

div.warning,
table tr.warning {
  background-color: $color-warning;
  border: 1px solid $color-warning-dark;
}

div.error {
  background-image: url('../images/error.png');
  background-position: 1% 48%;
  border: 0;

  a {
    position: relative;
    display: inline-block;
    color: $white;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      height: 1px;
      width: 100%;
      background: $white;
      opacity: 1;
      transition: all $duration $easing;
    }

    &:hover,
    &:active {
      color: $white;

      &:after {
        opacity: 1;
        width: 0;
        left: 50%;
      }
    }
  }

}

div.error,
table tr.error {
  background-color: $color-error;
  border: 1px solid $color-error-dark;
}

// * Tabs
// =============================================================================

nav.tabs {
  width: 100%;
  background: $color-tabs-background;
  @include clearfix;

  ul {
    float: right;

    li {
      float: left;
      @include font-size(16);
    }
  }

  a {
    display: inline-block;
    padding: rem(10);
    color: $color-tabs-text;
    text-decoration: none;

    &:hover,
    &.active {
      background-color: $color-tabs-hover;
    }
  }
}


// * Fixes
// =============================================================================

// ** Contextual links

/*
.contextual-links-region {
  &:hover {
    > .contextual-links-wrapper {
      .contextual-links-trigger {
        display: block;
      }
    }
  }
}
*/

div.contextual-links-wrapper {
  padding: 2px 0;
  background-color: rgba($white, .5);
  border-radius: 2px;
}

.contextual-links-region:hover a.contextual-links-trigger {
  display: block;
  transition: none !important;  // override any inline styles or admin css
}

ul.contextual-links {
  height: auto;
  width: auto;
}

// ** Padding body > wysiwyg

body {
  .cke_editor,
  .cke_editable {
    padding-top: 0 !important;  // override any inline styles or admin css
  }
}

// ** Admin toolbar

#admin-toolbar * {
  float: none;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
}
input.admin-menu-search {
  height: auto;
}

// ** Hide invisible elements

.element-invisible {
  @include hide;
}

// ** Devel fix

div.krumo-root {
  color: $black;
  * {
    float: none;
  }
  a.krumo-name {
    color: #404000;
  }
}
