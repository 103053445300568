/**
 * Contact person block
 */

.bean-contact-person {
  margin-bottom: rem(23);
  @include font-size(18);

  span {
    font-weight: bold;
  }

  a {
    &:hover {
      color: $color-spot-1;
    }
  }
}
