////////////////////////////////////////////////////////////////////////////////
// *
// * Page: search results
// *
// *
////////////////////////////////////////////////////////////////////////////////


.search-results {
  //
}

.search-result {
  padding-bottom: rem($base-vertical-space);
  margin-bottom: rem($base-vertical-space); // keep the same as for other lists of nodes or elements
  border-bottom: 1px solid $color-border;
}
