/**
 * Contact pane
 */

.contact-block {
  padding: rem(35) rem(18) rem(46) rem(18);
  margin-bottom: rem(40);
  h2 {
    color: $white;
    margin-bottom: rem(38);
  }

  @include bp-sm {
    margin-bottom: 0;
  }
}
