///////////////////////////////////////////////////////////////////////////////
// *
// * Panels: rows as slides
// *
// * http://keithclark.co.uk/articles/practical-css-parallax/
// * http://keithclark.co.uk/articles/pure-css-parallax-websites/
// *
////////////////////////////////////////////////////////////////////////////////


// TO DO:
// - apply for doormat as well (different .tpl to add the wrappers?)
// - add overlay posibility to background
// - stretch img to fill div via JS

// * General slide styling
// =============================================================================

// ** our slide

.row--parallax {
  overflow: hidden;
  position: relative;
  //min-height: calc(100vh - #{$header-height-closed}px); // set your slide height
  min-height: 100%;
  background: $white;
  @include display(flex);
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
}

.parallax__layer {
  width: 100%;
  @include transform(translateZ(0)); // force hardware acceleration on older webkit
  @include transition(all .01s linear); // help smooth out the changing transforms when scrolling
}

// ** slide content

.parallax__layer--base {
  position: relative;
  z-index: 10;
}

// ** slide background

.parallax__layer--back {

  @include slide-fill;
  z-index: 0;

  // reset other wrappers with positioning
  // so we can hook our bg on the proper div
  .panel-pane {
    position: static;
  }

  // hide title and other fields if any
  h2,
  .pane-title {
    display: none;
  }

  // position the div containing the background-image
  // might need to change this class name (if you're using a different field)
  // don't forget to also replace this in the mixins and the parallax JS
  .field-name-field-cta-background-image {
    display: none;
  }

  // if img-tag is uses, stretch to fill
  // use js to detect ratio and overwrite h or w accordingly
  img {
    display: block;
    width: 100%;
    height: auto;
    opacity: 0.2;
  }
}


// * Individual slides
// =============================================================================

// -- Set backgrounds and such

// -- Row visual
.row--visual {
  //min-height: calc(100vh - #{$header-height-open}px);
  background: transparent;
  @include clearfix;
  @include display(flex);

  .parallax__layer--base {
    @include order(2);
  }
  .parallax__layer--back {
    @include order(1);
    position: relative;
    height: initial;
    img {
      opacity: 1;
    }
  }
}

// -- Row 1
.row--01 {
  background: transparent;
  .container {
    padding-top: rem(80);
    padding-bottom: rem(80);

    .col {
      &:first-of-type {
        @include grid-columns-responsive(12, 12, 5, 5);
      }
      &:last-of-type {
        @include grid-columns-responsive(12, 12, 6, 6);
      }
    }
  }
}

// -- Row 2
.row--02 {
  background: $color-spot-1;
  color: $white;
  h2,
  h3 {
    color: $white;
  }
  .container {
    padding-top: rem(60);
    padding-bottom: rem(60);
  }
}

// -- Row 3
.row--03 {
  background: transparent;
  .container {
    padding-top: 0;
    padding-bottom: rem(70);
  }
}

// -- several rows combined
.row--04,
.row--07,
.row--08 {
  .container {
    padding-top: 0;
    padding-bottom: rem(66);
  }
}

// -- Row 5
.row--05 {
  background: $color-spot-1;
  color: $white;
  h2,
  h3 {
    color: $white;
  }
  .container {
    padding-top: rem(110);
    padding-bottom: rem(110);
  }
}

// -- Row 6
.row--06 {
  .container {
    padding-top: rem(80);
    padding-bottom: rem(80);
  }
}

// -- Row contact
.row--contact {
  background: $color-contact-bg;
  @include clearfix;
  .contact-block {
    background: $color-spot-1;
  }
  .container {
    padding: 0 rem(15);
  }
  .panel-pane {
    margin-bottom: rem(40);
  }
}

/**
 * Breakpoints
 */
// * Individual slides
// =============================================================================
@include bp-sm {
  // -- Parallax
  .parallax__layer--back {
    // position the div containing the background-image
    // might need to change this class name (if you're using a different field)
    // don't forget to also replace this in the mixins and the parallax JS
    .field-name-field-cta-background-image {
      display: block;

      //@include slide-fill;
      div {
        @include slide-fill;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
      }
    }
  }

  // -- Visual layer
  .row--visual {
    display: block;
    .parallax__layer--base {
      position: absolute;
      bottom: 0;
    }
  }

  // -- Contact row
  .row--contact {
    .col--1-2:last-of-type {
      padding-top: rem(42);
      padding-bottom: rem(42);
    }
  }
}

@include bp-lg {
  // -- Contact row
  .row--contact {
    .panel-pane {
      margin-bottom: 0;
    }
    .row-contact-left,
    .row-contact-right {
      width: calc(50% - 30px);
      float: left;
    }
    .row-contact-right {
      margin-left: $gutter;
    }
  }
}
