////////////////////////////////////////////////////////////////////////////////
// *
// * Keyframe animations
// *
////////////////////////////////////////////////////////////////////////////////


// * Keyframes
// =============================================================================

// *
// * Animate the mobile navigation
// *
@keyframes mobileNavOpen {
  0% {
    display: none;
    opacity: 0;
    height: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scaleY(0);
    height: auto;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    height: auto;
  }
}

@keyframes mobileNavClose {
  0% {
    opacity: 1;
    transform: scaleY(1);
    height: auto;
  }
  99% {
    display: block;
    opacity: 0;
    transform: scaleY(0);
    height: auto;
  }
  100% {
    display: none;
    opacity: 0;
    height: 0;
  }
}
