////////////////////////////////////////////////////////////////////////////////
// *
// * CTA bean
// *
////////////////////////////////////////////////////////////////////////////////


// Use this file to style the cta bean.
// Set the default settings below. Don't guess, check the design for these settings!
// Some parameters always need a value - these parameters are assigned an asterisk.
//
// If there are instances of cta beans that differ from the default styling, create a new parameters
// variable and pass this along in the mixin. See the sample code at the bottom.
//
// $width: (*) if not a full-screen block, you can set a fixed width.
// -- Try to avoid this and make columns in the Panel row instead
// $height: (*) set the min-height of the bean (stretches further with text content).
// $color: (*) the color of the text.
// $backgroundcolor: the background-color of the cta bean. Use $light: false if you want this color to be used as the background!
// $fullscreen: set this to true if the block will fill an entire row - default is set to true.
// $text-align: this sets the flow of the cta bean. Can have 3 values:
//  - center: centers all the content.
//  - left: aligns the text to the left and the cta button to the right, best used with fullwidth cta bean.
//  - right: aligns the text to the right and the cta button to the left, best used with fullwidth cta bean.
// $vertical-align: align the button vertically
//  - top
//  - middle
//  - bottom

// * Parameters
// =============================================================================

// ** DEFAULT PARAMETERS

$default: (
  color: $white,
  backgroundcolor: $color-btn-secondary-bg,
  text-align: left,
  vertical-align: top,
  fullscreen: true,
);

$block: (
  color-title: $color-grey,
  color: $color-grey-light,
  backgroundcolor: transparent,
  text-align: left,
  vertical-align: top,
  fullscreen: false,
);

$button: (
  color: $white,
  backgroundcolor: transparent,
  text-align: center,
  fullscreen: false,
);

// ** CUSTOM PARAMETERS
//
// $left: (
//   color: $white,
//   backgroundcolor: $color-btn-secondary-bg,
//   flow: left,
//   fullscreen: true,
// );
// $middle: (
//   color: $white,
//   backgroundcolor: $color-btn-secondary-bg,
//   flow: middle,
//   fullscreen: true,
// );
// $right: (
//   color: $white,
//   backgroundcolor: $color-btn-secondary-bg,
//   flow: right,
//   fullscreen: true,
// );


// * Example
// =============================================================================
//
// !! EXAMPLE CODE !!
// LEFT
// .bean-cta-bean {
//   &.view-mode-default {
//     @include cta-bean($left);
//     .field-name-field-link-cta-bean-bean {
//       a {
//         @include button;
//       }
//     }
//   }
// }
// MIDDLE
// .bean-cta-bean {
//   &.view-mode-default {
//     @include cta-bean($middle);
//     .field-name-field-link-cta-bean-bean {
//       a {
//         @include button;
//       }
//     }
//   }
// }
// RIGHT
// .bean-cta-bean {
//   &.view-mode-default {
//     @include cta-bean($right);
//     .field-name-field-link-cta-bean-bean {
//       a {
//         @include button;
//       }
//     }
//   }
// }
// !! EXAMPLE CODE !!


// * Bean
// =============================================================================

.bean-cta-bean {

  &.view-mode-default {
    @include cta-bean($default);

    .field-name-field-link-cta-bean-bean {
      a {
        @include button;
      }
    }
  }

  // In header,
  // don't need title, text or background, just the button

  &.view-mode-button,
  header.is-primary & {

    padding: 20px 15px;

    .field-name-title-field,
    h2,
    .field-name-field-body-cta-bean-bean {
      @include hide;
    }

    .field-name-field-link-cta-bean-bean {
      a {
        @include button;
        width: 100%;
      }
    }

    .bean__actions {
      padding: 0;
    }

    // make space for main menu to flow below bean & logo
    @include bp-sm {
      padding: 0;
      margin-left: 30px;
    }
  }

  // display as a block, for example in a column or part of a bigger whole
  // change the class name to whatever distinctive parent it has

  .row--cols & {
    @include cta-bean($block);

    margin-bottom: 0;
    padding: 0;
  }
}
