/**
 * Text + Text bean
 * Bean split in 2 halves, both halves which are filled up with text.
 */

.bean-text-text {
  .field-type-text-long p:last-of-type {
    margin-bottom: 0;
  }
  .col:first-of-type {
    margin-bottom: rem(30);
  }

  @include bp-sm {
    .ds-header {
      width: 50%;
    }
    .col:first-of-type {
      margin-bottom: 0;
    }
  }
  @include bp-md {
    .col--1-2 {
      &.ds-content--left {
        padding-left: 0;
      }
      &.ds-content--right {
        padding-right: 0;
      }
    }
  }
}
.row .container .bean-text-text {
  margin-bottom: 0;
}
