////////////////////////////////////////////////////////////////////////////////
// *
// * Sticky sidebar
// *
// *
////////////////////////////////////////////////////////////////////////////////

@include bp-sm {

  // a hook for the sidebar to position itself to
  .row--main > .container > .cols {
    position: relative;
  }

  // set sidebar absolute
  // top position will be variable and set with JS
  .col--aside--right {

    &.is-fixed {
      position: absolute;
      right: 0;
    }
  }
}
