////////////////////////////////////////////////////////////////////////////////
// *
// * Parallax: Footer
// *
////////////////////////////////////////////////////////////////////////////////


// * Sticky footer
// =============================================================================
// ** Variant on: https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/

body,
html {
  height: 100%; // stretch window to fill screen
}

.page-wrapper {
  @include display(flex); // set flex on parent
  @include flex-direction(column); // set flex to display children in column
  min-height: 100%; // stretch page wrapper to fill the screen vertically
}

// set flex to child, will fill the remaining space
// which pushes any element that comes after (e.g. the footer) down
.page {
  @include flex(1 0 auto);
}


/* Doormat
============================================================================= */

.doormat {
  background: $color-doormat-bg;
  padding: rem(45) 0;
  position: relative;

  .bean-image {
    max-width: rem(300);
  }
}

// ** Doormat blocks: columns & general styling

.doormat__block {

  // base styling
  margin-bottom: rem(20); // + 30 from .doormat == 50 at bottom
  color: $color-grey-light;

  // grid
  // reset to full width because we'll be using a ds template for columns
  // or simply remove the .cols class in the page tpl's
  @extend %col--full;

  // Titles
  h2,
  h3 {
    @extend %h4;
    margin-bottom: rem(15);
    color: $white;
  }
}

// -- Text block in doormat
.bean-text {
  @include font-size(12, 16);
}

/**
 * Breakpoints
 *
 */
@include bp-sm {
  .block-bean-footer-image {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    max-width: rem(175);
    padding: 0;
    margin: 0;
  }
}

@include bp-md {
  .doormat {
    .col--1-2 {
      margin-left: 25%;
    }
  }
}

/* Footer
============================================================================= */

footer.is-primary {

  padding: rem(22) rem(10);
  text-align: center;
  background: $color-footer-bg;
  color: $color-footer;
  @include clearfix;

  // ** Doormat blocks: general

  .container > [class*='block-'] {
    display: inline-block;
    @include font-size(12);
    margin-right: rem(10);
    a {
      @include links($color-footer, $color-spot-1);
    }

    &:first-child {
      margin-bottom: rem(5);
    }

    @include bp-sm {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  .footer-menu {
    display: block;
  }

  .copyright {
    display: inline-block;
  }

  p {
    margin: 0;
  }

  /**
   * Breakpoints
   */
  @include bp-sm {
    .footer-menu {
      margin: 0 rem(10);
    }
  }
  @include bp-md {
    .container {
      margin: 0 auto;
      > div {
        &:first-of-type {
          float: left;
        }
        &:last-of-type {
          float: right;
        }
      }
    }
  }
}
