////////////////////////////////////////////////////////////////////////////////
// *
// * Fields: author and date
// *
////////////////////////////////////////////////////////////////////////////////


// -- simple date/author field combined

.field-name--ct-base-date-and-author-field {
  margin-bottom: rem($base-vertical-space);
  @include font-size($base-font-size - 1);
  font-style: italic;
  font-weight: $font-weight-bold;
}

// -- date and author split

.field-name-submitted-by,
.field-name-post-date,
.field-name-author {
  display: inline-block;
  margin-right: rem(5);
  margin-bottom: rem($base-vertical-space);
  font-style: italic;
}

.field-name-post-date {
  margin-bottom: rem($base-vertical-space);
  @include font-size($base-font-size - 1);
  @include font-awesome($icon-calendar, $color-base, 16px, before, text-bottom, 0 rem(5) 0 0);
  &:before {
    font-weight: normal;
  }
}

.field-name-author {
  margin-bottom: rem($base-vertical-space);
  @include font-size($base-font-size - 1);
  @include font-awesome($icon-user, $color-base, 16px, before, text-bottom, 0 rem(5) 0 0);
  &:before {
    font-weight: normal;
  }
}

