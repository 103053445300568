////////////////////////////////////////////////////////////////////////////////
// *
// * Header search block
// *
////////////////////////////////////////////////////////////////////////////////


.header-top-block-search {
  width: 30px;
  height: 30px;
  margin: 0 0 0 15px;
  border-left: 1px solid $color-border;

  .icon {
    cursor: pointer;
    display: block;
    position: absolute;
    z-index: 30; // over the search field
    top: 0;
    padding: 4px 0;
    width: 29px;
    font-style: normal;
    text-align: center;
    @include transition(all $duration $easing);

    &:before {
      @include transition(all $duration $easing);
    }

    &:hover {
      &:before {
        color: $color-link;
      }
    }
  }

  // ** cancel icon

  .icon-cancel {
    opacity: 0;
    left: rem(-$padding-container);
    @include font-awesome($icon-times, $color-grey-light, 16px, before);
  }

  // ** use a search icon instead of big button

  .icon-search {
    right: 0;
    @include font-awesome($icon-search, $color-grey-light, 16px, before);

    // reset button styling
    // only if a button tag is used
    background: transparent;
    height: auto;
    min-height: 0;

    @include hide-icontext();
  }

  // if the submt input has not been replaced by a button element
  // put the submit over the icon in order to catch the click/touch submit

  // input[type='submit'] {
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   z-index: 0;
  //   width: 30px;
  //   height: 30px;
  //   padding: 0;
  //   opacity: 0;
  // }

  // ** text field

  input[type='text'] {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20; // over the other content in header top
    margin: 0;
    padding: 0;
    height: 30px;
    width: 0;
    @include font-size(14, 18);
    color: $color-base;
    background: $white;
    border: 0;
    border-bottom: 1px solid $color-border;

    @include placeholder {
      color: $white;
    }
  }

  // ** Open state of the search block (visible text field & cancel icon)

  .js-open {

    .icon-cancel,
    .icon-search {

      top: 5px;

      &:before {
        font-size: 21px;
      }
    }

    .icon-search {
      right: rem($padding-container);
    }

    .icon-cancel {
      left: rem($padding-container);
      opacity: 1;
    }

    input[type='text'] {
      padding: 0 50px;
      width: 100%;
      height: 40px;
      @include font-size(18);

      border-left: 1px solid $color-border;
      border-right: 1px solid $color-border;
    }
  }
}
