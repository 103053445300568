////////////////////////////////////////////////////////////////////////////////
// *
// * Mixins: Beans
// *
////////////////////////////////////////////////////////////////////////////////

// * Social Bean mixin
// =============================================================================

@mixin social-bean($settings) {

  // Set the variables
  $color: map-get($settings, color);
  $hovercolor: map-get($settings, hovercolor);
  $font-size: map-get($settings, font-size);
  $showtext: map-get($settings, showtext);
  $showicon: map-get($settings, showicon);
  $icon-color: map-get($settings, icon-color);
  $icon-size: map-get($settings, icon-size);
  $icon-background: map-get($settings, icon-background);
  $border-width: map-get($settings, border-width);
  $border-style: map-get($settings, border-style);
  $border-color: map-get($settings, border-color);
  $border-radius: map-get($settings, border-radius);
  $inline: map-get($settings, inline);

  // ** The basic setup

  // centering
  @if ($inline == false or $inline == null) {
    position: relative;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  text-align: left; // reset doormat/footer setting

  [class*='field-name-field-'] {
    position: relative;
    line-height: 1;
    margin-bottom: rem($base-vertical-space/2);

    a {
      color: $color;

      &:hover {
        color: $hovercolor;
        &:before {
          color: $hovercolor;
        }
      }

      @if ($showtext) {
        @include font-size($font-size);
      } @else {
        font-size: 0;
        line-height: #{$font-size}px;
      }
    }
  }

  // ** As list or inline items

  @if ($inline) {
    // use the partial selector (looks for classes containing this string)
    [class*='field-name-field-'] {
      display: inline-block;
      margin-bottom: 0;

      @if ($showtext == true) {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        &:before {
          line-height: 14px;
        }
      } @else {
        margin-right: 7px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  // ** Visible icon or not

  @if ($showicon == true) {

    a {
      line-height: inherit;
      &:before {
        width: rem($icon-size + 2);
        text-align: center;
        line-height: #{$icon-size}px;

        // if there's a background or border, set it here
        // also adjust sizing and width
        @if ($icon-background != null) {
          background: $icon-background;
        }
        @if ($border-width != null) {
          border: $border-width $border-style $border-color;
        }
        @if ($border-radius != null) {
          border-radius: $border-radius;
        }
        @if ($icon-background != null OR $border-width != null) {
          width: rem($icon-size * 2);
          height: rem($icon-size * 2);
          line-height: rem(($icon-size * 2) - ($border-width * 2));
        }
      }
    }

    .field-name-field-facebook-social-bean {
      a {
        @if ($showtext == true) {
          @include font-awesome($icon-facebook, $icon-color, #{$icon-size}px, before, baseline, 0 7px 0 0);
        } @else {
          @include font-awesome($icon-facebook, $icon-color, #{$icon-size}px, before, baseline, 0 0 0 0);
        }
      }
    }
    .field-name-field-twitter-social-bean {
      a {
        @if ($showtext == true) {
          @include font-awesome($icon-twitter, $icon-color, #{$icon-size}px, before, baseline, 0 7px 0 0);
        } @else {
          @include font-awesome($icon-twitter, $icon-color, #{$icon-size}px, before, baseline, 0 0 0 0);
        }
      }
    }
    .field-name-field-linkedin-social-bean {
      a {
        @if ($showtext == true) {
          @include font-awesome($icon-linkedin, $icon-color, #{$icon-size}px, before, baseline, 0 7px 0 0);
        } @else {
          @include font-awesome($icon-linkedin, $icon-color, #{$icon-size}px, before, baseline, 0 0 0 0);
        }
      }
    }
    .field-name-field-google-social-bean {
      a {
        @if ($showtext == true) {
          @include font-awesome($icon-googleplus, $icon-color, #{$icon-size}px, before, baseline, 0 7px 0 0);
        } @else {
          @include font-awesome($icon-googleplus, $icon-color, #{$icon-size}px, before, baseline, 0 0 0 0);
        }
      }
    }
    .field-name-field-instagram-social-bean {
      a {
        @if ($showtext == true) {
          @include font-awesome($icon-instagram, $icon-color, #{$icon-size}px, before, baseline, 0 7px 0 0);
        } @else {
          @include font-awesome($icon-instagram, $icon-color, #{$icon-size}px, before, baseline, 0 0 0 0);
        }
      }
    }
  }

  @include bp-sm {
    @if ($inline == false or $inline == null) {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
  }
}


// * USP Bean mixin
// =============================================================================

@mixin usp-bean($settings) {
  // Set the variables
  $color: map-get($settings, color);
  $color-title: map-get($settings, color-title);
  $font-size: map-get($settings, font-size);
  $line-height: map-get($settings, line-height);
  $showicon: map-get($settings, showicon);
  $icon-color: map-get($settings, icon-color);
  $icon-size: map-get($settings, icon-size);
  $icon-padding: map-get($settings, icon-padding);
  $icon-background: map-get($settings, icon-background);
  $icon: map-get($settings, icon);
  $icon-backgroundborderradius: map-get($settings, icon-backgroundborderradius);
  $inline: map-get($settings, inline);
  $columns: map-get($settings, columns);

  text-align: center;

  h1,
  h2,
  h3 {
    @if ($color-title != null and $color-title != false) {
      color: $color-title;
    } @else {
      color: $color;
    }
    @include font-size($font-size-h4, $line-height-h4);
  }

  .field-name-field-usp-bean {
    @include clearfix;
  }

  .field-name-field-usp-bean-body {
    margin-bottom: rem(($icon-size + ($icon-padding * 2)) / 2); // space after text
  }

  .field-items {
    // for centering the items
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(-($base-vertical-space / 2)); // negate the top margins of items inside this
    padding-top: 0.1px; // hack to make top margin work
    text-align: left;
  }

  .field-item {
    position: relative;
    padding-bottom: rem((($icon-size + ($icon-padding * 2)) / 2) - ($base-line-height / 2));
    margin-top: rem($base-vertical-space / 2);
    color: $color;

    @if ($line-height != null and $line-height != false) {
      @include font-size($font-size, $line-height);
    }
    @else {
      @include font-size($font-size);
    }

    @if ($showicon == true) {
      min-height: rem($icon-size + ($icon-padding * 2));
      padding-left: rem($icon-size + ($icon-padding * 2) + 12);
      @include font-awesome($icon, $icon-color, #{$icon-size}px, before, baseline, 0 0 0 0);
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        background: $icon-background;
        text-align: center;
        line-height: #{$icon-size + ($icon-padding * 2)}px;
        @include square(rem($icon-size + ($icon-padding * 2)));
        border-radius: $icon-backgroundborderradius;
      }
    }
  }

  @include bp-sm {
    text-align: left;

    .field-items {
      display: block;
      margin-left: 0;
      margin-right: 0;

      @if ($inline != false and $inline != null) {
        margin-right: rem(-10);
      }
      @if ($columns != false and $columns != null) {
        margin-right: rem(-$gutter); // space for padding on last item
        @include clearfix;
      }
    }

    .field-item {
      // reset centering
      @if ($columns == null or $columns == false) {
        float: none;
      }
      left: 0;
      clear: none;

      @if ($inline) {
        display: inline-block;
        padding-right: rem(10); // space between cols
      } @else {
        display: block;
      }

      @if ($columns != null and $columns != false ) {
        float: left;
        padding-right: rem($gutter); // space between cols
        width: (100% / $grid-columns * $columns);
      }
    }
  }
}


// * Office Hours Bean mixin
// =============================================================================

@mixin office-hours-bean($settings) {
  // Set the variables
  $color: map-get($settings, color);
  $font-size: map-get($settings, font-size);
  $alternating: map-get($settings, alternating);
  $light: map-get($settings, light);

  @include font-size($font-size);
  color: $color;

  br {
    display: none; // there's a bug where extra breaks are added to the content
  }

  // reset footer/doormat left alignment
  [class*='field-name-'] {
    text-align: left;
  }
  .field-name-title-field {
    text-align: center;
  }

  // extra line for extra info
  .field-name-field-extra-info-office-hours {
    display: block;
    font-weight: bold;

    @if ($alternating == true) {
      padding: 4px 7px;

      @if ($light == true) {
        border: 1px solid rgba($white, 0.15);
      }
      @else {
        border: 1px solid rgba($black, 0.15);
      }

    } @else {
      padding: 4px 0;
    }

    label {
      @include hide;
    }
  }

  .oh-wrapper {
    display: block;
  }

  .oh-display {
    display: block;

    @if ($alternating == true) {

      padding: 4px 7px;

      @if ($light == true) {
        &:nth-child(even) {
          background: rgba($white, 0.15);
        }
      }
      @else {
        &:nth-child(even) {
          background: rgba($black, 0.15);
        }
      }
    } @else {
      padding: 4px 0;
    }
  }

  .oh-current-open,
  .oh-current-closed {
    padding: 4px 7px;
    border: 1px solid;
  }

  .oh-current-open {
    color: $color-spot-2;
    border-color: $color-spot-2;
  }

  .oh-current-closed {
    color: $color-error;
    border-color: $color-error;
  }

  @include bp-sm {
    .field-name-title-field {
      text-align: left;
    }
  }
}


// * Contact Bean mixin
// =============================================================================

@mixin contact-bean($settings) {
  // Set the variables
  $color: map-get($settings, color);
  $hovercolor: map-get($settings, hovercolor);
  $font-size: map-get($settings, font-size);
  $line-height: map-get($settings, line-height);
  $showicon: map-get($settings, showicon);
  $icon-color: map-get($settings, icon-color);
  $icon-size: map-get($settings, icon-size);
  $icon-background: map-get($settings, icon-background);
  $border-width: map-get($settings, border-width);
  $border-style: map-get($settings, border-style);
  $border-color: map-get($settings, border-color);
  $border-radius: map-get($settings, border-radius);
  $inline: map-get($settings, inline);

  // center and align left on the icon:
  @if ($inline == false or $inline == null) {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  text-align: center;

  color: $color;

  @if ($line-height != null and $line-height != false) {
    @include font-size($font-size, $line-height);
  }
  @else {
    @include font-size($font-size);
  }

  a {
    color: $color;
    &:hover {
      color: $hovercolor;
      &:before {
        color: $hovercolor;
      }
    }
  }

  .field-name-title-field {
    text-align: center;
  }

  @if ($showicon) {

    [class^='field-name-field-'],
    .field-type-text {
      position: relative;
      padding-left: rem($icon-size + 2 + 5);

      @if ($icon-background != null) {
        margin-bottom: rem($icon-size/2);
      }

      &:before {
        position: absolute;
        left: 0;
        top: 2px;
        width: rem($icon-size + 2);
        text-align: center;
        line-height: #{$icon-size}px;

        // if there's a background or border, set it here
        // also adjust sizing and width
        @if ($icon-background != null) {
          background: $icon-background;
        }
        @if ($border-width != null) {
          border: $border-width $border-style $border-color;
        }
        @if ($border-radius != null) {
          border-radius: $border-radius;
        }
        @if ($icon-background != null OR $border-width != null) {
          width: rem($icon-size * 2) !important; // override the width coming from the fontawesome mixin
          height: rem($icon-size * 2);
          line-height: rem(($icon-size * 2) - ($border-width * 2));
        }
      }
    }

    .field-name-field-company-contact-bean {
      padding-left: #{$icon-size + 11 + 5}px;
      font-weight: bold;
    }
    .field-name-field-address-contact-bean {
      @include font-awesome($icon-location, $icon-color, #{$icon-size}px, before, baseline, 0 5px 0 0);
    }
    .field-name-field-tel-contact-bean {
      @include font-awesome($icon-phone, $icon-color, #{$icon-size}px, before, baseline, 0 5px 0 0);
    }
    .field-name-field-mobile-contact-bean {
      @include font-awesome($icon-mobile, $icon-color, #{$icon-size}px, before, baseline, 0 5px 0 0);
    }
    .field-name-field-email-contact-bean {
      @include word-break;
      @include font-awesome($icon-email, $icon-color, #{$icon-size}px, before, baseline, 0 5px 0 0);
    }

    .field-name-field-vat-number-contact-bean {
      margin-top: rem($base-vertical-space);
      @include font-awesome($icon-briefcase, $icon-color, #{$icon-size}px, before, baseline, 0 5px 0 0);
    }
  }

  @if ($inline) {

    text-align: center;

    .field-name-field-address-contact-bean {
      @include text-nobreak;
      margin-right: 20px;
    }

    .field-name-field-tel-contact-bean {
      display: inline-block;
      margin-right: 0; // nothing next to it on phone
      @include bp-sm {
        margin-right: 20px;
      }
    }

    .field-name-field-address-contact-bean,
    .field-name-field-mobile-contact-bean {
      margin-bottom: 0;
    }

    .field-name-field-company-contact-bean,
    .field-name-field-tel-contact-bean,
    .field-name-field-email-contact-bean {
      margin-bottom: 0;
    }

    .field-name-field-email-contact-bean,
    .field-name-field-address-contact-bean {
      display: none; // hide on phone, only leave phone number
      @include bp-sm {
        display: inline-block;
      }
    }

    .street-block,
    .locality-block,
    .country {
      display: inline-block;
      margin-right: 5px;
    }

    .street-block {
      &:after {
        content: ', ';
        display: inline;
      }
    }

    .thoroughfare {
      display: inline;
    }

    .locality-block {
      padding-left: 0;
      &:after {
        display: none;
      }
    }

    .country {
      display: none;
    }

    .header-top & {
      text-align: center;
    }

  } @else {

    [class^='field-name-field-'],
    .field-type-text {
      padding-left: 28px;
      &:before {
        width: 19px; // + 4px natural spacing + 5px margin = 28px
      }
    }

    .street-block {
      display: inline-block;
    }

    // move the location icon to company name
    .field-name-field-company-contact-bean {
      @include font-awesome($icon-location, $icon-color, #{$icon-size}px, before, baseline, 0 5px 0 0);
    }

    .field-name-field-address-contact-bean {
      // no location icon on address bc we have one on company name
      margin-bottom: rem($base-vertical-space);
      &:before {
        display: none;
      }
    }
  }

  @include bp-sm {

    text-align: left;

    @if ($inline == false or $inline == null) {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    .field-name-title-field {
      text-align: left;
    }
  }
}


// * CTA Bean mixin
// =============================================================================

@mixin cta-bean($settings) {
  // Set the variables
  $width: map-get($settings, width);
  $height: map-get($settings, height);
  $color: map-get($settings, color);
  $color-title: map-get($settings, color-title);
  $backgroundcolor: map-get($settings, backgroundcolor);
  $text-align: map-get($settings, text-align);
  $vertical-align: map-get($settings, text-align);
  $fullscreen: map-get($settings, fullscreen);

  position: relative;
  padding: 50px 0;
  // set a fixed width if not fullscreen
  @if ($fullscreen != true) {
    width: $width;
  }
  text-align: center;
  color: $color;
  @if ($backgroundcolor != null) {
    background: $backgroundcolor;
  }

  // ** fields over bg image

  [class*='field-name'] {
    position: relative;
    z-index: 2; // higher than div with background
  }

  // ** title

  h1,
  h2, {
    @if ($color-title != null) {
      color: $color-title;
    } @else {
      color: $color;
    }
    @include font-size($font-size-h3, $line-height-h3);
    margin-bottom: rem($base-vertical-space);
  }

  // ** body

  .field-name-field-body-cta-bean-bean,
  .field-name-body-field {
    margin-bottom: rem($base-vertical-space);
  }


  // * * * * * * * * * * * * * * * * * * * * *
  // * Breakpoint: Tablet and larger screens
  // * * * * * * * * * * * * * * * * * * * * *

  @include bp-sm {

    // ** min-height and center text vertically

    @if ($height) {
      min-height: $height;
    }
    // vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // ** content fields

    h1,
    h2,
    .field-name-field-body-cta-bean-bean,
    .field-name-body-field {
      margin-bottom: rem(2 * $base-vertical-space);
      @include font-size($font-size-h2, $line-height-h2);
    }

    // ** body

    .field-name-field-body-cta-bean-bean,
    .field-name-body-field {
      @include font-size($font-size-intro, $line-height-intro);
    }
  }

  // * * * * * * * * * * * * * * * * * * * * *
  // * Breakpoint: Desktop and larger screens
  // * * * * * * * * * * * * * * * * * * * * *

  @include bp-md {

    @if ($text-align == left OR $text-align == right) {
      .container--conditional {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .bean__content {
        flex: 1;
      }

      // center vertically
      @if ($vertical-align == middle) {
        .bean__actions {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      @if ($vertical-align == bottom) {
        .bean__actions {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
      }

      @if ($vertical-align == top) {
        .bean__actions {
          display: flex;
          align-items: flex-top;
          justify-content: flex-top;
        }
      }
    }

    // ** if button goes next to text, put fields next to eachother
    @if ($text-align == left) {

      text-align: left;

      .bean__actions {
        padding-left: 20px;
      }

      // .field-name-title-field,
      // .field-name-field-title-cta-bean,
      // .field-name-field-body-cta-bean-bean,
      // .field-name-body-field {
      //   float: left;
      //   width: (100% / 12 * 8);
      // }

      h1,
      h2,
      .field-name-field-body-cta-bean-bean,
      .field-name-body-field {
        margin-bottom: rem($base-vertical-space/2);
      }

      .field-name-field-body-cta-bean-bean,
      .field-name-body-field {
        margin-bottom: 0;
      }

      .field-name-field-link-cta-bean-bean {
        text-align: right;
      }

    } @else if ($text-align == right) {

      text-align: right;

      .bean__content {
        order: 1;
      }

      .bean__actions {
        padding-right: 20px;
      }

      // .field-name-title-field,
      // .field-name-field-title-cta-bean,
      // .field-name-field-body-cta-bean-bean,
      // .field-name-body-field {
      //   float: right;
      //   width: (100% / 12 * 8);
      // }

      h1,
      h2,
      .field-name-field-body-cta-bean-bean,
      .field-name-body-field {
        margin-bottom: rem($base-vertical-space / 2);
      }

      .field-name-field-body-cta-bean-bean,
      .field-name-body-field {
        margin-bottom: 0;
      }

      .field-name-field-link-cta-bean-bean {
        text-align: left;
      }

    } @else if ($text-align == center) {
      // ** if button doesn't need to go next to text
      // ** and there is enough space, set a max-width
      @if ($fullscreen) {
        .field-name-title-field,
        .field-name-field-title-cta-bean,
        .field-name-body-field,
        .field-name-field-body-cta-bean-bean {
          max-width: 66.66%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}


// * Image CTA Bean mixin
// =============================================================================

@mixin image-cta-bean($settings) {
  // Set the variables
  $color: map-get($settings, color);
  $color-title: map-get($settings, color-title);
  $font-size: map-get($settings, font-size);
  $line-height: map-get($settings, line-height);
  $text-align: map-get($settings, text-align);
  $imgfloat: map-get($settings, imgfloat);

  text-align: $text-align;

  h1,
  h2,
  h3 {
    @if ($color-title != null) {
      color: $color-title;
    } @else {
      color: $color;
    }
    margin-bottom: rem($base-vertical-space / 2);
    @include font-size($font-size-h4, $line-height-h4);
  }

  .field-name-field-body-cta-bean {
    color: $color;
    @if ($line-height != null) {
      @include font-size($font-size, $line-height);
    }
    @else {
      @include font-size($font-size);
    }
  }
  @include bp-sm {
    @if ($imgfloat == left OR $imgfloat == right) {
      .field-name-field-image-cta-bean {
        float: $imgfloat;
        width: 50%;
        @if ($imgfloat == right) {
          margin-left: 20px;
        }
        @else if ($imgfloat == left) {
          margin-right: 20px;
        }
      }
      .field-name-image-cta-bean-custom-title,
      .field-name-field-body-cta-bean,
      .field-name-field-link-cta-bean {
        width: calc(50% - 20px);
        @if ($imgfloat == right) {
          float: left;
          clear: left;
        }
        @else if ($imgfloat == left) {
          float: right;
          clear: right;
        }
      }
    }
  }
}


// * Image Background Bean mixin
// =============================================================================

@mixin image-background-cta-bean($settings) {

  // Set the variables
  $height: map-get($settings, height);
  $width: map-get($settings, width);
  $color: map-get($settings, color);
  $color-dark: map-get($settings, color-dark);
  $color-complex: map-get($settings, color-complex);
  $color-title: map-get($settings, color-title);
  $showoverlay: map-get($settings, showoverlay);
  $overlaycolor: map-get($settings, overlaycolor);
  $overlayalpha: map-get($settings, overlayalpha);
  $fullscreen: map-get($settings, fullscreen);
  $text-align: map-get($settings, text-align);
  $vertical-align: map-get($settings, vertical-align);
  $background-position: map-get($settings, background-position);

  position: relative;

  .wrapper {
    padding: 50px 0;
  }

  // set a fixed width if not fullscreen
  @if ($fullscreen != true) {
    width: $width;
  }
  text-align: center;
  color: $color;

  // ** fields over bg image

  [class*='field-name'] {
    position: relative;
    z-index: 2; // higher than div with background
  }

  // ** title

  h1,
  h2, {
    @include font-size($font-size-h2, $line-height-h2);
    @if ($color-title != null) {
      color: $color-title;
    } @else {
      color: $color;
    }
    margin-bottom: rem($base-vertical-space);
  }

  // ** body

  .field-name-field-body-img-bkgr-cta-bean,
  .field-name-field-body-cta-bean,
  .field-name-body-field {
    margin-bottom: rem($base-vertical-space);
  }

  // ** bg image

  .field-name-bg-imagebk-cta-bean {
    &,
    & div {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0; // lowest level bc tekst goes over it
      height: 100%;
      width: 100%;
    }

    div {
      background-position: $background-position;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @if ($showoverlay == true) {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1; // over the background-image
        width: 100%;
        height: 100%;
        background: rgba($overlaycolor, $overlayalpha);
      }
    }
  }

  // ** if backgroundCheck plugin is used

  .background--light {
    &,
    h1,
    h2 {
      color: $color-dark;
    }
  }

  .background--complex {
    &,
    h1,
    h2 {
      color: $color-complex;
    }
  }

  // * * * * * * * * * * * * * * * * * * * * *
  // * Breakpoint: Tablet and larger screens
  // * * * * * * * * * * * * * * * * * * * * *

  @include bp-sm {

    // ** min-height and center text vertically

    display: flex;
    flex-direction: column;
    width: 100%;

    .wrapper {
      width: 100%;
      @if ($height) {
        min-height: $height;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    // ** content fields

    h1,
    h2,
    .field-name-field-body-img-bkgr-cta-bean,
    .field-name-field-body-cta-bean,
    .field-name-body-field {
      margin-bottom: rem(2 * $base-vertical-space);
      @include font-size($font-size-h1, $line-height-h1);
    }

    // ** body

    .field-name-field-body-img-bkgr-cta-bean,
    .field-name-field-body-cta-bean,
    .field-name-body-field {
      @include font-size($font-size-intro, $line-height-intro);
    }
  }

  // * * * * * * * * * * * * * * * * * * * * *
  // * Breakpoint: Desktop and larger screens
  // * * * * * * * * * * * * * * * * * * * * *

  @include bp-md {

    @if ($text-align == left OR $text-align == right) {

      // get everyting ready to put the items in a line
      .container--conditional {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      // stretch content to fill remaining space in row
      .bean__content {
        flex: 1;
      }

      // aligns button vertically

      @if ($vertical-align == middle) {
        .bean__actions {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      @if ($vertical-align == bottom) {
        .bean__actions {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
      }

      @if ($vertical-align == top) {
        .bean__actions {
          display: flex;
          align-items: flex-top;
          justify-content: flex-top;
        }
      }
    }

    // ** if button goes next to text, put fields next to eachother
    @if ($text-align == left) {

      .bean__actions {
        padding-left: 20px;
      }

      // .field-name-title-field,
      // .field-name-field-title-cta-bean,
      // .field-name-field-body-cta-bean,
      // .field-name-body-field {
      //   float: left;
      //   width: (100% / 12 * 8);
      // }

      .field-name-field-link-imagebk-cta-bean {
        text-align: right;
      }

      h1,
      h2,
      .field-name-field-body-img-bkgr-cta-bean,
      .field-name-field-body-cta-bean,
      .field-name-body-field {
        margin-bottom: rem($base-vertical-space / 2);
      }

      .field-name-field-body-img-bkgr-cta-bean,
      .field-name-field-body-cta-bean,
      .field-name-body-field {
        margin-bottom: 0;
      }

    } @else if ($text-align == right) {

      text-align: right;

      .bean__content {
        order: 1;
      }

      .bean__actions {
        padding-right: 20px;
      }

      // .field-name-title-field,
      // .field-name-field-title-cta-bean,
      // .field-name-field-body-cta-bean,
      // .field-name-body-field {
      //   float: right;
      //   width: (100% / 12 * 8);
      // }

      .field-name-field-link-imagebk-cta-bean {
        text-align: left;
      }

      h1,
      h2,
      .field-name-field-body-img-bkgr-cta-bean,
      .field-name-field-body-cta-bean,
      .field-name-body-field {
        margin-bottom: rem($base-vertical-space / 2);
      }

      .field-name-field-body-img-bkgr-cta-bean,
      .field-name-field-body-cta-bean,
      .field-name-body-field {
        margin-bottom: 0;
      }


    } @else if ($text-align == center) {
      // ** if button doesn't need to go next to text
      // ** and there is enough space, set a max-width
      @if ($fullscreen) {
        .field-name-title-field,
        .field-name-field-title-cta-bean,
        .field-name-field-body-img-bkgr-cta-bean,
        .field-name-body-field,
        .field-name-field-body-cta-bean {
          max-width: 66.66%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
