/**
 * Image + Text bean
 * Bean split in two halves, one halve an image, one halve text.
 *
 * DS: Image right, Image left, Image round left, default
 */

.bean-image-text {
  // -- General
  margin-bottom: 0!important; // overwrite base theme .row .container .entity-bean
  .col:first-of-type {
    margin-top: 0;
  }

  // -- Link field
  .field-type-link-field {
    margin-top: rem(35);
    a {
      @include button;
    }
  }

  // -- Text
  .field-type-text-long {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  // -- Round Image
  &.view-mode-round_image_left {
    .field-type-image {
      margin-bottom: rem(40);
      img {
        border-radius: 100%;
        margin: 0 auto;
      }
    }
  }

  // -- Image left
  &.view-mode-image_left {
    .ds-content--left {
      margin-bottom: rem(30);
    }
  }

  // -- Image right
  &.view-mode-image_right {
    .ds-content--left {
      margin-top: rem(67);
    }
    // -- Text
    .field-type-text-long {
      margin-bottom: rem(35);
    }
  }

  /**
   * Breakpoints
   */
  @include bp-sm {
    // -- Image left
    &.view-mode-image_left {
      .ds-content--left {
        margin-top: rem(50);
        margin-bottom: 0;
      }
    }
  }

  @include bp-lg {
    // -- Image right
    &.view-mode-image_right {
      .field-type-image {
        width: 100vw;
        img {
          width: 50vw;
          margin-right: calc(1px - ((100vw - 1180px) / 2) - 1px);
        }
      }
    }

    // -- Image left
    &.view-mode-image_left {
      .ds-content--right {
        padding-left: rem(30);
      }
      .field-type-image {
        width: 100vw;
        img {
          width: 50vw;
          margin-left: calc(1px - ((100vw - 1180px) / 2) - 1px);
        }
      }
    }
  }
}
