////////////////////////////////////////////////////////////////////////////////
// *
// * Colorbox
// *
// * Reset and modify styles for this lightbox plugin
// *
////////////////////////////////////////////////////////////////////////////////


#cboxWrapper {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

//** Arrows
// =============================================================================

#cboxPrevious,
#cboxNext {
  width: 50%;
  height: 100%;
  background: 0 none;
  opacity: 0;
  transition: all $duration $easing;

  &:before {
    position: absolute;
    text-indent: 0;
    top: 50%;
    width: rem(44);
    height: rem(44);
    line-height: rem(44);
    background: rgba($white, .75);
    transition: all $duration $easing;
  }

  &:hover {
    opacity: 1;
  }
}

#cboxPrevious {
  @include font-awesome($icon-chevron-left, $color-spot-2, 28px);

  &:before {
    left: -64px;
  }

  &:hover {
    &:before {
      left: 0;
    }
  }
}

#cboxNext {
  left: auto;
  right: 0;
  @include font-awesome($icon-chevron-right, $color-spot-2, 28px);

  &:before {
    right: -64px;
  }

  &:hover {
    &:before {
      right: 0;
    }
  }
}

//** Close button
// =============================================================================

#cboxClose {
  right: rem(-5);
  min-height: rem(28);
  background: 0 none;
  @include font-awesome($icon-times, $color-spot-2, 21px);

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    line-height: rem(33);
    text-indent: 0;
    transition: all $duration $easing;
  }

  &:hover {
    &:before {
      color: darken($color-spot-2, 10%);
    }
  }
}

//** Image count
// =============================================================================

#cboxCurrent {
  left: 0;
}
