////////////////////////////////////////////////////////////////////////////////
// *
// * Image Background CTA Bean
// *
////////////////////////////////////////////////////////////////////////////////

// Use this file to style the image background cta bean.
// Set the default settings below. Don't guess, check the design for these settings!
// Some parameters always need a value - these parameters are assigned an asterisk.
//
// If there are instances of image background cta beans that differ from the default styling, create a new parameters
// variable and pass this along in the mixin. See the sample code at the bottom.
//
// $width: (*) if not a full-screen block, you can set a fixed width
// -- Try to avoid this and make columns in the Panel row instead
// $height: (*) set the min-height of the bean (stretches further with text content).
// $showoverlay: set this to true to add an overlay color to the image - default is true.
// $overlaycolor: set the color of the overlay.
// $overlayalpha: set the alpha of the color of the overlay.
// $fullscreen: set this to true if the block will fill an entire row - default is set to true.
// $text-align: this sets the flow of the cta bean. Can have 3 values:
//  - center: centers all the content.
//  - left: aligns the text to the left and the cta button to the right, best used with fullwidth cta bean.
//  - right: aligns the text to the right and the cta button to the left, best used with fullwidth cta bean.
// $vertical-align: align the button vertically
//  - top
//  - middle
//  - bottom


// * Parameters
// =============================================================================

// ** DEFAULT PARAMETERS

$default: (
  height: rem(500),
  color: $white,
  color-dark: $black,
  color-complex: $color-grey,
  showoverlay: false,
  //overlaycolor: $white,
  //overlayalpha: 0.5,
  text-align: center,
  vertical-align: top, // align the button vs the text
  fullscreen: true,
  background-position: center top
);

// ** CUSTOM PARAMETERS

// $var-name: (
//   color: ...,
// )


// * Example
// =============================================================================
//
// !! EXAMPLE CODE !!
// $not-fullscreen: (
//   height: 300px,
//   width: 50%;
//   color: $white,
//   fullscreen: false,
// );
//
// .bean-image-background-cta-bean {
//   .front & {
//     &.view-mode-default {
//       @include image-background-cta-bean($not-fullscreen);
//     }
//   }
// }
// !! EXAMPLE CODE !!


// * Bean
// =============================================================================

.bean-image-background-cta-bean {

  .field-name-field-link-imagebk-cta-bean {
    a {
      @include button;
    }
  }

  &.view-mode-default {
    @include image-background-cta-bean($default);
    //margin-bottom: rem($base-vertical-space * 2);
  }
}
