////////////////////////////////////////////////////////////////////////////////
// *
// * Parallax: Navigation
// *
////////////////////////////////////////////////////////////////////////////////


// * Mobile menu
// =============================================================================

header {

  // ** trigger for mobile menu

  .wrapper-navigation .nav-title {
    font-size: 0;
    position: absolute;
    cursor: pointer;
    top: rem(23);
    right: rem(0);
    padding: rem(15);
    @include font-awesome($icon-hamburger, $color-grey, 16px);

    &:before {
      @include transition(all $duration $easing);
    }

    &:hover {
      &:before {
        color: $color-link;
      }
    }
  }

  // ** wrapper for the mobile navigation + searchblock

  .wrapper-navigation {
    @include clearfix;
    margin-bottom: 0;
    clear: both;
    background-color: $color-navigation-bg;
    margin-left: rem(-$padding-container);
    margin-right: rem(-$padding-container);
    overflow: hidden;
    max-height: 0;
    @include transition(all .35s ease-out);

    //optional: scale animation effect
    //@include animation(mobileNavClose .35s ease-out);
    //@include transform-origin(center top);

    &.js-open {
      //margin-bottom: rem(20);
      max-height: rem(1200);
      @include transition(all .5s ease-in);
      //optional: scale animation effect
      //@include animation(mobileNavOpen .35s ease-in);
    }
    .nav-content,
    .nav-section-primary {
      @include clearfix;
    }
  }
}

// * Main navigation
// =============================================================================

.nav-main,
.header__system--main-menu {
  clear: both;

  // first level ul

  & > ul {

    // styling for all items (even sublevels)

    li {
      @include font-size(14, 18);

      a {
        display: block;
        padding: rem(10) 0 rem(10) rem(20);

        &,
        &:visited {
          color: $white;
          background-color: $color-spot-1;
          border-bottom: lighten($color-spot-1, 10%);
        }

        &:hover {
          color: $white;
          background-color: darken($color-spot-1, 10%);
        }
      }

      &:last-child a {
        border-bottom: 0;
      }
    }

    // item with submenu

    li.expanded {
      position: relative;

      // clickable icon to open/close submenu on touch (added via JS)
      .js-trigger {
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: rem(38);
        height: rem(38);

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          margin: rem(-2.5) 0 0 rem(-5);
          height: 0;
          width: 0;
          border-left: 5px solid transparent;
          border-top: 5px solid $white;
          border-right: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
      }

      // submenu
      // hidden by default
      ul {
        background-color: lighten($color-spot-1, 10%);
      }

      li {
        overflow: hidden;
        max-height: 0;
        @include font-size(13);
        @include transition(all .25s ease-out);

        a {
          padding-left: rem($padding-container * 2);
        }
      }

      // visible after interaction
      &.js-open {

        // rotate the icon
        .js-trigger {
          @include transform(rotate(180deg));
        }

        li {
          max-height: rem(100);
          @include transition(all .25s ease-in);
        }
      }
    }
  }
}

// * Footer navigation
// =============================================================================
.footer-menu {
  margin-bottom: rem(5);
  a {
    font-style: italic;
  }
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * Breakpoint: tablet & wider
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

// -- switch to big menu when desktop
// -- change to bp-sm if you want to switch earlier
// -- don't forget to change the breakpoint check in JS .mobileMenu() as well

@include bp-sm {

  // * Mobile menu stuff
  // =============================================================================

  header {

    .nav-title {
      display: none;
    }

    .wrapper-navigation {
      clear: none;
      float: right;
      margin: rem(50) 0 rem(20) 0;
      max-height: none;
      overflow: visible;
      background-color: transparent;

      // keep the floats from breaking
      min-width:100px;
      width: auto !important;
      width: 100px;
    }
  }

  // * Main navigation
  // =============================================================================

  .nav-main,
  .header__system--main-menu {
    //float: right;
    clear: none;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    right: 0;

    & > ul {
      margin: 0;
      margin-right: rem(15);
      padding: 0;

      // styling for all menu items

      li {
        display: inline-block;
        padding-left: rem(30);

        a {
          display: inline-block;
          border-bottom: 0 none;
          padding: 0;

          &,
          &:visited {
            color: $color-link;
            background-color: transparent;
          }

          &:hover {
            color: $color-spot-1;
            background-color: transparent;
          }
        }
      }

      // item with submenu

      li.expanded {
        position: relative;

        // arrow or icon to open/close
        // created via JS
        // turn it off in self.dropdownMenu if you don't need one
        .js-trigger {
          width: rem(10);

          &:before {
            border-top: 6px solid $color-grey-light;
          }
        }

        // submenu
        // hidden by default
        ul {
          position: absolute;
          left: 0;
          top: rem(38); // height of the menu item
          z-index: 9;
          min-width: rem(150);
          background-color: $color-grey-light;

          &:before {
            content: '';
            display: none;
            position: absolute;
            left: rem($padding-container);
            top: rem(-12);
            border-left: 6px solid transparent;
            border-top: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid $color-grey-light;
          }
        }

        li {
          display: block;

          a {
            display: block;
            padding-left: rem($padding-container);
            @include links($white, $white);
            border-top: 1px solid $white;

            &:hover,
            &.active {
              background-color: $color-spot-2;
            }
          }

          &:first-child {
            a {
              border-top: 0 none;
            }
          }
        }

        // visible after interaction

        &.js-open {
          ul {
            &:before {
              display: block;
            }
          }

          // align submenu for last item to the right
          // otherwise it could get pushed off-screen
          &:last-child {
            ul {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }
  }

  // * Footer navigation
  // =============================================================================
  .footer-menu {
    margin-bottom: rem(5);
  }
}

@include bp-md {
  .nav-main,
  .header__system--main-menu {
    & > ul {
      margin-right: 0;
    }
  }
}

@include bp-lg {
  // * Main navigation
  // =============================================================================
  .nav-main {
    & > ul {
      // styling for all menu items
      li {
        padding-left: rem(33);
        a {
          padding: 0;
        }
      }
    }
  }
}
