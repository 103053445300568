////////////////////////////////////////////////////////////////////////////////
// *
// * Mixins: Structure & grid, with Bourbon
// *
////////////////////////////////////////////////////////////////////////////////

// ** We have 2 possible grid systems to use, 'custom' or 'neat'
// ** This is set in config/_grid.scss


// * Handy Bourbon Neat mixins
// =============================================================================
// -- For more Bourbon mixins see: http://thoughtbot.github.io/neat-docs/latest/

// ** Rows
// @include row();
// -- Designates the element as a group (row) of columns in the grid layout
// -- http://thoughtbot.github.io/neat-docs/latest/#row

// ** Columns
// @include span-columns(6 of $grid-columns);
// -- http://thoughtbot.github.io/neat-docs/latest/#span-columns

// **  Omega
// @include omega(2n);
// -- http://thoughtbot.github.io/neat-docs/latest/#omega
// -- This is used to remove the margin on the last column
// -- Don't need this if it's the last col in the row

// ** Shift
// @include shift(1);
// -- http://thoughtbot.github.io/neat-docs/latest/#shift
// -- This makes it possible to move a column up to the left or right


// * Overview
// =============================================================================

// ** grid-row
// ** grid-columns
// ** grid-columns-responsive
// ** grid-shift
// ** grid-reset
// ** max-width


// * Mixins: general
// =============================================================================

// ** Group your columns together (don't forget to do this!!!)
// -- sets a clearfix, clears stuff, etc…
// -- OPTIONS: makes the columns equal height using flex, by default

@mixin grid-row($flex:true, $spacing: ($gutter/2)) {

  @if ($which-grid == 'neat') {

    @include row();

  } @else {

    @include clearfix;
    margin-left: rem(-$spacing);
    margin-right: rem(-$spacing);
  }

  // equal height columns inside the row, using flexbox
  @if ($flex) {
    @include bp-sm {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// **  Use this to create columns from elements
// --  (don't forget to include grid-row on your columns wrapper!!!)
//
// --  OPTIONS:
// --  - set flex to 'true' if the columns wrapper has flex as well in order to wrap equal height cols
// --  - this avoids having to reset the 'clear' and margin (Neat) properties and makes it possible to nest cols
//
// --  NOTE: $number is the number of columns in a row of 12
// --  EXAMPLE: see the .doormat in partials base/_footer

@mixin grid-columns($number, $flex:true, $spacing:($gutter/2)) {

  @if ($which-grid == 'neat') {

    @if($number > 1) {
      @include span-columns($number);

      // if we're not using flex on the grid-row,
      // clear every row (because no flex equal height cols)
      // keep in mind this means you'll have to use grid-reset
      // for all nested grid-row
      @if($flex == false) {
        // remove margin on last child and nth-child
        // doing it manually bc omega is being stupid
        &:nth-child(#{$grid-columns/$number}n),
        &:last-child {
          margin-right: 0;
        }
        // clear every row
        // doing it manually bc omega is being stupid
        &:nth-child(#{$grid-columns/$number}n+1) {
          clear: left;
        }
      }

      @if($flex) {
        display: flex;
      }
    }
    @else {
      @include fill-parent();
    }

  } @else {
    float: left;
    padding-left: rem($spacing);
    padding-right: rem($spacing);
    @if($number > 1) {
      width: (100% * $number / $grid-columns);
    } @else {
      width: 100%;
    }

    @if($flex == false) {
      // if we're not using flex on the grid-row,
      // clear every row (because no flex equal height cols)
      // keep in mind this means you'll have to use grid-reset
      // for all nested grid-row
      &:nth-child(#{$grid-columns/$number}n+1) {
        clear: left;
      }
    }
  }
}

// ** Create columns, but for different breakpoints
// -- USAGE: @include grid-columns-responsive(12, 6, 6, 3)
// --          Creates columns that span 12 columns on bp-xs-small (0-479)
// --          Creates columns that span 6 columns on bp-xs-wide (480-768
// --          Creates columns that span 6 columns on bp-sm (768-940)
// --          Creates columns that span 3 columns on bp-md (940-infinity)

@mixin grid-columns-responsive($col-bp-xs-1:12, $col-bp-xs-2:false, $col-bp-sm:12, $col-bp-md:12, $flex:true, $spacing:($gutter/2)) {

  @if ($col-bp-xs-2 == false) {
    @include bp-xs-only {
      @include grid-columns($col-bp-xs-1, $flex, $spacing);
    }
  } @else {
    @include bp-xs-small {
      @include grid-columns($col-bp-xs-1, $flex, $spacing);
    }

    @include bp-xs-wide {
      @include grid-columns($col-bp-xs-2, $flex, $spacing);
    }
  }

  @include bp-sm-only {
    @include grid-columns($col-bp-sm, $flex, $spacing);
  }
  @include bp-md {
    @include grid-columns($col-bp-md, $flex, $spacing);
  }
}

// ** Move the column a certain distances
// -- expressed as a positive or negative number of columns

@mixin grid-shift($number) {
  @if ($which-grid == 'neat') {
    @include shift($number);

  } @else {
    margin-left: (100% * $number / $grid-columns);
  }
}

// **  Grid-reset mixin: use this to change the number of set columns at any breakpoint
//
// --  OPTIONS: $number is the number of the previously set 'grid-columns'
// --  NOTE: Only needed if you're NOT using flex on grid-row and grid-columns
// --  NOTE 2: Put this BEFORE including a new 'columns' declaration! Otherwise it won't work

@mixin grid-reset($number) {

  @if ($which-grid == 'neat') {
    // reset margin on last child and nth-child
    &:nth-child(#{$grid-columns/$number}n),
    &:last-child {
      margin-right: flex-gutter();
    }
    // reset every row
    &:nth-child(#{$grid-columns/$number}n+1) {
      clear: none;
    }

  } @else {
    // reset every row (in case of no equal height cols)
    &:nth-child(#{$grid-columns/$number}n+1) {
      clear: none;
    }
  }
}

// ** Max-width: provides a max-width to an element and centers it.
// -- USAGE: @include max-width;           This will take a max-width defined by the mixin below.
// -- USAGE: @include max-width(123px);    This will use the value included.

@mixin max-width($width: null) {

  // if no fixed width set,
  // use the grid breakpoints and variables
  @if ($width == null) {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width-xs;

    @include bp-sm {
      max-width: none;
    }

    @include bp-md {
      max-width: $max-width-sm;
    }

    @include bp-lg {
      max-width:  $max-width-md;
    }
  }
  // else, set a fixed width
  @else {
    margin-left: auto;
    margin-right: auto;
    max-width: $width;
  }
}
