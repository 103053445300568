////////////////////////////////////////////////////////////////////////////////
// *
// * Breadcrumb
// *
////////////////////////////////////////////////////////////////////////////////


.breadcrumb {

  margin-top: rem(-5); // compensate for bottom margin of title

  a,
  span {
    display: inline-block;

    &,
    &:visited {
      color: $color-base;
      &:hover,
      &:active {
        color: darken($color-spot-1, 10%);
      }
    }
  }

  .delimiter {
    font-size: 0;
    @include font-awesome($icon-chevron-right, $color-spot-3, 8px, ':before', baseline, 0 4px 0 4px );

    &:last-child {
      display: none;
    }
  }


}
