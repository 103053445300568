////////////////////////////////////////////////////////////////////////////////
// *
// * Grid
// *
////////////////////////////////////////////////////////////////////////////////

// -- We can use mixins to build columns using Bourbon Neat
// -- Check out _mixins-grid.scss to find out how to use them
//
// -- However, writing our own gives us more flexibility.
// -- E.g better alignment when using borders or backgrounds
// -- We're using neg. margins on .cols and padding on .col
// -- Used with Panels and Display Suite templates
//
// -- Because it can be a pain to manipulate the class-names in a drupal tpl,
// -- most these classes are also available as placeholders, but try to fix
// -- the classes in tpl's first


// * Grouping columns together
// =============================================================================

// ** columns wrapper

.cols {
  @include grid-row(true);
}

// ** styles needed by every column

.col {
  // these were moved to the individual classes, so we don't need this anymore
}


// * Columns with breakpoints
// =============================================================================

// ** These classes + placeholders are used to describe specific column structures per breakpoint
// -- EXAMPLE: eg. on Event detail
// -- EXAMPLE 2: eg. on Frontpage: the different views and columns

.col--full,
%col--full {
  @include grid-columns(12, true);
}

// half width (6/12)
.col--1-2,
%col--1-2 {
  @include grid-columns-responsive(12, 12, 6, 6, true);
}

// 1 of a group of 3 columns total (4/12)
.col--1-3,
%col--1-3 {
  @include grid-columns-responsive(12, 12, 6, 4, true);
}

// 1 of a group of 4 (3/12)
.col--1-4,
%col--1-4 {
  @include grid-columns-responsive(12, 12, 6, 3, true);
}

// 1 of a group of 6 (2/12)
.col--1-6,
%col--1-6 {
  @include grid-columns-responsive(12, 6, 3, 2, true);
}

// 2 of a group of 3 (8/12)
.col--2-3,
%col--2-3 {
  @include grid-columns-responsive(12, 12, 6, 8, true);
}

// 3 of a group of 4 (9/12)
.col--3-4,
%col--3-4 {
  @include grid-columns-responsive(12, 12, 12, 9, true);
}

// 5 of a group of 12 (5/12)
.col--5-12,
%col--5-12 {
  @include grid-columns-responsive(12, 12, 6, 5, true);
}

// 7 of a group of 12 (7/12)
.col--7-12,
%col--7-12 {
  @include grid-columns-responsive(12, 12, 6, 7, true);
}


// * Content columns
// =============================================================================

// Panel main row, with optional sidebars
// -- eg. on Contact page

.col--aside--left,
.col--aside--right, {
  @include grid-columns-responsive(12, 12, 4, 3, true);
}

// content for 2 sidebars

.col--content--center {
  @include grid-columns-responsive(12, 12, 4, 6, true);
}

// Content for single sidebar

.col--content--left {

  // @include grid-columns-responsive(12, 12, 8, 9, true);

  @include bp-xs-only {
    @include grid-columns(12);
  }

  @include bp-sm-only {
    @include grid-columns(8);
    padding-right: rem(30);
  }

  @include bp-md {
    @include grid-columns(8); // 8 cols
    margin-right: (100% / 12 * 1); // 1 col
  }
}

.col--content--right {

  // @include grid-columns-responsive(12, 12, 8, 9, true);

  @include bp-xs-only {
    @include grid-columns(12);
  }

  @include bp-sm-only {
    @include grid-columns(8);
  }
  @include bp-md {
    @include grid-columns(8); // 8 cols
    margin-left: (100% / 12 * 1); // 1 col
  }
}


// Only content
.col--content--full {
  @include grid-columns(12, true);
}

// Exceptions for detail pages
// here we always leave a gap where a sidebar would be (if filled in)
.panel--coolflex--detail {
  & > .row > .container > .cols > .col--content--full {
    @include bp-xs-only {
      @include grid-columns(12);
    }

    @include bp-sm-only {
      @include grid-columns(8);
    }
    @include bp-md {
      @include grid-columns(8);
    }
  }
}
