////////////////////////////////////////////////////////////////////////////////
// *
// * Mixins: Forms
// *
////////////////////////////////////////////////////////////////////////////////


// * Overview
// =============================================================================

// ** placeholder
// ** floating-labels


// * Mixins
// =============================================================================

// ** make floating labels
// -- Option to make them look like material Google design
// -- https://material.google.com/components/text-fields.html#text-fields-labels


// ** make a group of elements floating labels

@mixin floating-labels-group($material:false) {
  // in case of entity form
  .field-type-email,
  .field-type-text,
  .field-type-text-long,
  .field-type-tel,
  .field-type-phone,
  .field-type-telephone,
  .field-type-date,
  // in case of webform
  .webform-component-email,
  .webform-component-textfield,
  .webform-component-textarea,
  .webform-component-tel,
  .webform-component-phone,
  .webform-component-telephone,
  .webform-component-date {
    @include floating-labels-element($material);
  }
}

// ** or make an individual item floating

@mixin floating-labels-element($material:false) {
  position: relative;

  label {
    // put it over the input field
    // Make it look like input text
    position: absolute;
    font-weight: normal;

    @if ($material) {
      left: 0;
      top: rem(36); // overlap with input field
      @include font-size(17, 18);
    } @else {
      left: rem(24);
      top: rem(25); // overlap with input field
      @include font-size(17);
    }

    &.is-active,
    &.has-value {

      .indicator-not-required {
        opacity: 0;
      }

      @if ($material) {
        left: 0;
        top: rem(10);
        @include font-size(14);
      } @else {
        left: rem(24);
        top: rem(12);
        @include font-size(14);
      }
    }

    &.is-active {
      color: $color-form-active;
    }
  }

  #{$all-text-inputs},
  textarea {

    padding: rem(33) rem(24) rem(15);

    &::placeholder {
      color: transparent;
      @include font-size(17);
    }

    @if ($material) {
      padding-left: 0;
      padding-right: 0;
      @include font-size(17);
      color: $color-form-active;
      background-color: $white;
      border: 0;
      border-bottom: 1px solid $color-grey-light;

      &:focus,
      &:focus:hover {
        background-color: transparent;
        border-bottom-color: $color-form-active;
        outline: 0;
      }

      &:disabled,
      &[disabled] {
        opacity: 1;
        background-color: transparent;
        border-bottom-style: dashed;
      }
    } @else {
      @include font-size($base-font-size + 3);
    }
  }

  select {
    padding: 20px rem(40) 20px rem(24);
    height: 70px;
    @include font-size(17);
  }

  .js-select {
    &:after {
      right: rem(24);
    }
  }
}
