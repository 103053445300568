////////////////////////////////////////////////////////////////////////////////
// *
// * Field: Tags
// *
////////////////////////////////////////////////////////////////////////////////


// -- Tags

.field-name-field-blog-tags,
.field-name-field-article-tags,
.field-name-field-event-tags {

  .field-items {
    margin-top: rem(-5);
    margin-left: rem(-5);
  }

  .field-item {
    margin-top: rem(5);
    margin-left: rem(5);
  }

  .field-item,
  a {
    display: inline-block;
  }

  a {
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      height: 1px;
      width: 100%;
      background: $color-spot-2;
      opacity: 1;
      transition: all $duration $easing;
    }

    &:hover,
    &:active {

      &:after {
        opacity: 1;
        width: 0;
        left: 50%;
      }
    }
  }
}
