////////////////////////////////////////////////////////////////////////////////
// *
// * Custom gallery
// *
////////////////////////////////////////////////////////////////////////////////


//** the general slider (showing 1 or more items)
// =============================================================================

// ** container

.gallery__section {
  position: relative;

  @if ($gallery-arrows-visible == 'hover') {
    &:hover {
      a {
        opacity: 1;
      }

      .gallery__prev {
        left: 0;
      }

      .gallery__next {
        right: 0;
      }
    }
  }

  // fade-out effect on half slides
  &.has-halves {

    .gallery__section__inner {

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: rem(100);

        // gradient background fade
        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);

      }
    }
  }
}

.gallery__section__inner {
  position: relative;
  overflow: hidden;
  //height: 0; // start closed
  transition: height $duration $easing;
}

// ** the row of slides which will be animated

.gallery__row {
  list-style: none;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

// ** individual slides
// -- widths calculated by JS

.gallery__item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    height: auto;
    max-width: none; // allow upscaling
  }

  .inner {
    width: 100%;
  }

  &.has-ratio {
    overflow: hidden; // hide overflowing images (height will be cropped bc fixed dimensions)
    .inner {
      position: absolute;
      height: 100%; // stretch to fill item bc img is absolute
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: all $duration $easing;
    }
  }
}

// for sliding animation

.gallery__section--slide {
  .gallery__row {
    width: 999999px; // will be overridden by JS
    margin: 0 0 0 rem(-10); // same as distance between the slides
    transition: left $duration $easing;
  }

  .gallery__item {
    float: left;
    margin: 0 0 0 rem(10); // distance between slides
  }
}

// for fading animation

.gallery__section--fade {

  .gallery__row {
    position: static; // we want the section to be the hook for position
  }

  .gallery__item {
    @include block-fill;
    z-index: 0;
    top: 0;
    opacity: 0;
    transition:  all $duration $easing;

    // vertical center things
    display: flex;
    align-items: center;
    justify-content: center;
    // make them wrap (as intended)
    flex-wrap: wrap;

    &.is-active {
      opacity: 1;
      z-index: 1;
    }
  }
}


//** the main section
// =============================================================================

.gallery__section--main {
  @if ($gallery-background) {
    background-color: $gallery-background-color;
  }
  @if ($gallery-borders) {
    border: 1px solid $gallery-color-borders;
  }
}

.gallery__row--main {
  // vertical center images
  display: flex;
  align-items: center;
  // make them wrap (as intended)
  flex-wrap: wrap;
}

//** the controls
// =============================================================================

.gallery__controls {

  a {
    cursor: pointer;
    display: block;
    position: absolute;
    z-index: 5;
    top: 0;
    width: rem(44);
    height: 100%;
    @if ($gallery-arrows-visible == 'hover') {
      opacity: 0;
    }

    &:after {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      width: 100%;
      transform: translateY(-50%);

      line-height: rem(44);
      text-align: center;
      height: rem(44);
      background-color: rgba($white, .75);
    }

    &:hover:after {
      background-color: rgba($white, 1);
    }
  }

  .gallery__prev {
    @if ($gallery-arrows-visible == 'hover') {
      left: -20px;
    } @else {
      left: 0;
    }
    @include font-awesome($icon-chevron-left, $color-spot-2, 21px, ':after');
  }

  .gallery__next {
    @if ($gallery-arrows-visible == 'hover') {
      right: -20px;
    } @else {
      right: 0;
    }
    @include font-awesome($icon-chevron-right, $color-spot-2, 21px, ':after');
  }

  @if ($gallery-arrows == false) {
    .gallery__section--main {
      .gallery__controls {
        display: none;
      }
    }
  }
}

//** the pager
// =============================================================================

// -- NOTE: pager not yet implemented in the JS plugin

.gallery__pager {
  // reset list
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  // position over the main slider
  position: absolute;
  left: 0;
  bottom: rem(20);
  width: 100%;
  // alignment
  text-align: center;
}

.gallery__page {
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 rem(5);
  background-color: $white;
  border: 2px solid $color-grey-light;
  @include hide-text;
  border-radius: 50%;
  transition: all $duration $easing;

  &:hover,
  &.is-active {
    background-color: $color-spot-2;
    border-color: $white;
  }
}

//** the thumbnail slider
// =============================================================================

.gallery__section--thumbs {
  margin-top: rem(10);
  margin-bottom: rem(20);

  .gallery__controls {

    a {
      width: rem(20);
      opacity: 1;
      background-color: rgba($white, .75);

      &:after {
        background-color: transparent;
      }

      &:hover {
        background-color: rgba($white, 1);
      }
    }

    // optional: style disabled arrows
    .is-disabled {
      //
    }

    .gallery__prev,
    .gallery__next {
      &:after {
        height: rem(21);
      }
    }

    .gallery__prev {
      left: 0;

      &:after {
        @include font-size(16);
      }
    }

    .gallery__next {
      right: 0;
      &:after {
        @include font-size(16);
      }
    }
  }
}

.gallery__thumb {
  cursor: pointer;
  position: relative;

  img {
    opacity: .5;
    background-color: $color-spot-2;
  }

  @if ($gallery-background) {
    background-color: $gallery-background-color;
  }
  @if ($gallery-borders) {
    border: 1px solid $gallery-color-borders;
  }

  &:hover,
  &.is-active {
    // background-color: $color-spot-2;
    img {
      opacity: 1;
      background-color: transparent;
    }
  }
}

