////////////////////////////////////////////////////////////////////////////////
// *
// * Config: Variables: Animation related
// *
////////////////////////////////////////////////////////////////////////////////


// * Transitions
// =============================================================================
// -- Set general transition params

$duration: 0.3s;
$easing: ease-in-out;


