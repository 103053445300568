////////////////////////////////////////////////////////////////////////////////
// *
// * Language dropdown
// *
////////////////////////////////////////////////////////////////////////////////


// * Language as text
// =============================================================================

.block-locale-language {


  h2 {
    @include hide;
  }

  ul {
    @include list-reset;

    li {
      display: inline;
      font-size: 0;
    }
  }

  .language-link {
    display: inline-block;
    padding: 0 7px;
    @include font-size(12);
    text-transform: uppercase;
    border-left: 1px solid $white;

    &,
    &:visited {
      color: $white;
    }

    &:hover {
      color: lighten($color-spot-1, 20%);
    }
  }

  li:first-child {
    .language-link {
      border-left: 0 none;
    }
  }

  li.active .language-link {
    color: $white;
    font-weight: bold;
    &:hover {
      color: lighten($color-spot-1, 20%);
    }
  }

  // hide the flag
  img {
    display: none;
  }
}

// * Language as dropdown
// =============================================================================

.lang_dropdown_form {

  label {
    @include font-size(12);
  }

  .dd-select,
  .dd-options {
    img {
      width: 16px;
      height: 11px;
      margin: 2px 10px 0 0;
    }
    label {
      margin-bottom: 0;
      cursor: pointer;
      text-transform: none;
      top: 0;
      line-height: 14px !important; // override inline styles
    }
  }
  .dd-select {
    border: 0;
    position: relative;
    padding: 7px 0;
  }
  .dd-selected {
    font-weight: normal;
    @include font-size(12);
    color: $color-grey-light;
    padding: 0;
  }
  .dd-pointer {
    position: absolute;
    right: 0;
    text-align: right;
    width: auto;
    border: 0 !important;  // override inline styles
    &.dd-pointer-down {
      top: 8px;
      @include font-awesome($icon-caret-down, $color-grey-light, 11px, after);
    }
    &.dd-pointer-up {
      top: 11px;
      &:after {
        content: $icon-caret-up;
      }
    }
  }
  .dd-options {
    background: $white;
    border: 1px solid $white;
    box-shadow: none;
    @include font-size(12);
    border-bottom: 0;
    border-top: 0;
    a {
      color: $color-grey-light;
      padding: 5px;
      display: block;
      border-bottom: 1px solid $color-border;
      &.dd-option-selected {
        background: $white;
        color: darken($color-grey-light, 20%);
      }
      &:hover {
        background: $color-border;
      }
    }
  }
  .header-top & {
    display: none;
    @include bp-sm {
      display: block;
    }
  }
}
