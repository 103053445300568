/**
 * FRONT PAGE
 */

/**
 * News
 */

.home-news {
  h2 {
    @include font-size(25);
    margin-bottom: rem(60);
  }
}
