////////////////////////////////////////////////////////////////////////////////
// *
// * Config: Variables: colors
// *
////////////////////////////////////////////////////////////////////////////////


// * Main
// =============================================================================

$color-base:        #1B180B;
$black:             #000000;
$white:             #FFFFFF;
$color-spot-1:      #556292;
$color-spot-2:      $color-spot-1;
$color-spot-3:      #EEF5FA;
$color-offwhite:    #F1F1F1;
$color-grey:        $color-base;
$color-grey-light:  #A5A5A5;
$color-heading:     #556292;

// * Regions
// =============================================================================

$color-border:          #48537C;
$color-header-top-bg:   $color-spot-1;
$color-navigation-bg:   $color-spot-1;
$color-doormat-bg:      $white;
$color-footer-bg:       #EEF5FA;
$color-footer:          #0E4E86;
$color-contact-bg:      #EEF5FA;

// * Structure
// =============================================================================

$color-page-header:     $white;
$color-page-header-bg:  $color-spot-1;

// * Buttons & links
// =============================================================================

$color-link:              $color-base;
$color-p-link:            $color-spot-1;
$color-btn-primary:       $white;
$color-btn-primary-bg:    $color-spot-2;
$color-btn-secondary:     #1B180B;
$color-btn-secondary-bg:  $white;

// ** Forms
// =============================================================================

$color-placeholder:          #A2A2A2;
$color-form:                 $color-base;
$color-form-bg:              $white;
$color-form-border:          $color-spot-1;
$color-form-active:          $color-spot-1;
$color-form-active-bg:       $white;
$color-form-active-border:   lighten($color-spot-1, 10%);
$color-form-disabled-bg:     $white;
$color-form-disabled-border: 1px solid #E2E2E2;
$color-form-error-border:    1px solid #E74C3C;

// * Tabs
// =============================================================================
$color-tabs-background:   $color-spot-1;
$color-tabs-text:         $white;
$color-tabs-hover:        lighten($color-tabs-background, 10%);

// * Alerts
// =============================================================================
$color-error:             #C94234;
$color-error-dark:        #C0392B;
$color-status:            #28AB69;
$color-status-dark:       #00B16A;
$color-warning:           #C67F2D;
$color-warning-dark:      #F2784B;
