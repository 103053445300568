/**
 * Banner bean
 */

.bean-banner {
  /**
   * Default view mode displays the text.
   */
  &.view-mode-default {
    .group-title {
      text-align: center;
      padding: rem(36) rem(40) rem(24) rem(40);
      h1 {
        font-style: italic;
      }
      .field-name-field-banner-subtitle {
        @include font-size(16);
      }
    }
  }

  /**
   * View mode for the background
   */
  &.view-mode-background {
    .field-type-image {
      img {
        min-height: rem(100);
        opacity: 1;
      }
    }
  }

  /**
   * Breakpoints
   */
  @include bp-sm {
    &.view-mode-default {
      width: 50%;
      background-color: $white;
      float: right;

      &:before {
        content: '';
        width: rem(45);
        height: 0;
        border-bottom: rem(94) solid white;
        border-left: rem(45) solid transparent;
        position: absolute;
        right: 50%;
      }

      .field-type-text {
        text-align: left;
        display: inline-block;
      }

      .group-title {
        text-align: left;
        h1 {
          margin-right: rem(10);
        }
      }
    }

    /**
     * View mode for the background
     */
    &.view-mode-background {
      .field-type-image {
        img {
          min-height: rem(330);
        }
      }
    }
  }
  @include bp-lg {
    /**
     * View mode for the background
     */
    &.view-mode-background {
      .field-type-image {
        img {
          min-height: rem(500);
          max-height: rem(530);
        }
      }
    }
  }
}
