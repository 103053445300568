/**
 * CTA bean
 */

.bean-cta {
  text-align: center;

  a {
    color: $white;
    @include font-size(16);
    font-style: italic;
    text-decoration: underline;
  }

  .field-type-text-long {
    margin-bottom: rem(37);
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  @include bp-md {
    width: 75%;
    margin: 0 auto;
  }
}

// -- Bean cta in parallax back layer
.parallax__layer--back .bean-cta {
  width: 100%;
}
.row .container .bean-cta {
  margin-bottom: 0;
}
