///////////////////////////////////////////////////////////////////////////////
// *
// * helpers: helpers for parallax
// *
////////////////////////////////////////////////////////////////////////////////

// **  positions and stretches an element to cover the whole parent

@mixin slide-fill() {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

@mixin slide-bg($image, $element:'.parallax__layer--back div') {

  // might need to change this class name (if you're using a different field)
  #{$element} {
    background-image: url($image);
  }
}

// **  Use this to create an overlay in an element
//
// --  OPTIONS:
// --  - $element is your element selector (string)
// --  - $color is your background-color
// --  - $opacity is the opacity for the color

@mixin slide-overlay($color, $opacity, $element:'.parallax__layer--back') {
  #{$element} {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color, $opacity);
    }
  }
}
