////////////////////////////////////////////////////////////////////////////////
// *
// * Set breakpoints
// *
// * Responsive indicattors for  use in JS (remove color & position when done)
// *
////////////////////////////////////////////////////////////////////////////////

body:after {
  position: fixed;
  bottom:0;
  right:0;
  display: block;
  padding: rem(10);
  content:'bp-xs';
  background: red;
  @if ($visual-indicators) {
    visibility: visible;
    opacity: 1;
  } @else {
    visibility: hidden;
    opacity: 0;
  }
  z-index: 444;
}
body.theme-debug-mode:after {
  visibility: visible;
}
/*
@if variable-exists(bp-xs-wide) {
  @include media($bp-xs-wide) {
    body:after {
      content:'bp-xs';
      background: #FF5700;
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
}

@if variable-exists(bp-xs-small) {
  @include media($bp-xs-small) {
    body:after {
      content:'bp-xs';
      background: orange;
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
}
*/

@if variable-exists(bp-sm) {
  @include media($bp-sm) {
    body:after {
      content: 'bp-sm';
      background: #FFCE00;
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
}

@if variable-exists(bp-md) {
  @include media($bp-md) {
    body:after {
      content: 'bp-md';
      background: yellow;
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
}

@if variable-exists(bp-lg) {
  @include media($bp-lg) {
    body:after {
      content: 'bp-lg';
      background: green;
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
}
